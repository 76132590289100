import axios from 'axios'

// initial state
const state = () => ({
    surveys: [],
    surveyTasks: [],
    surveyActivities: [],
    surveyStatus: [],
    patientSurveyTasks: [],
    surveyTaskGroups: [],
    currentPatientSurveyTask: null,
    patientSurveyStates: [
      {
        id: 1,
        title: 'Aktiv'
      },
      {
        id: 2,
        title: 'Abgeschlossen'
      },
      {
        id: 3,
        title: 'Nachbeobachtung'
      },
      {
        id: 4,
        title: 'Gescreent'
      },
      {
        id: 5,
        title: 'Abgelehnt'
      },
      {
        id: 6,
        title: 'Nicht teilgenommen'
      },
      {
        id: 7,
        title: 'Screening-Failure'
      },
    ]
})

// getters
const getters = {
  surveys: ({ surveys }) => surveys,
  surveyTasks: ({ surveyTasks }) => surveyTasks,
  surveyActivities: ({ surveyActivities }) => surveyActivities,
  surveyStatus: ({ surveyStatus }) => surveyStatus,
  surveyTaskGroups: ({ surveyTaskGroups }) => surveyTaskGroups,
  patientSurveyTasks: ({ patientSurveyTasks }) => patientSurveyTasks,
  currentPatientSurveyTask: ({ currentPatientSurveyTask }) => currentPatientSurveyTask,
  patientSurveyStates: ({ patientSurveyStates }) => patientSurveyStates,
  patientSurveyStateById: (state) => (id) => {
    if(!id || (id && id <= 0)) return null
    else {
      const found = state.patientSurveyStates.find(s => s.id === id)
      return (found ? found.title : null)
    }
  },
  patientSurveyActivityById: (state) => (id) => {
    if(!id || (id && id <= 0) || state.surveyActivities.length === 0) return null
    else {
      const found = state.surveyActivities.find(s => s.id === id)
      return (found ? found.title : null)
    }
  }
}
// actions
const actions = {
    async getSurveys({ commit }) {
        const surveys = (await axios.get('/surveys')).data
        commit('setSurveys', surveys)
    },
    async saveSurvey({ dispatch }, survey) {

      try {
        const result = await axios.post('/surveys', survey)
        dispatch("getSurveys")
        return result.data
      }
      catch (e) {
        return false
      }

    },
    async getSurveyActivities({ commit }) {
      const activities = (await axios.get('/surveyactivities')).data
      commit('setSurveyActivities', activities)
    },
    async saveSurveyActivity(state, activity) {

      try {
        const result = await axios.post('/surveyactivities', activity)
        return result.data
      }
      catch (e) {
        return false
      }

    },
    async deleteSurveyActivity(state, activity) {

      try {
        await axios.delete('/surveyactivities/'+activity.id)
      }
      catch (e) {
        return false
      }
      return true

    },
    async getSurveyTasks({ commit }, payload) {
      let param = '?'
      if(payload && payload.survey) param += '&survey='+payload.survey
      const surveytasks = (await axios.get('/surveytasks' + param)).data
      commit('setSurveyTasks', surveytasks)
    },
    async saveSurveyTask(state, task) {

      try {
        const result = await axios.post('/surveytasks', task)
        return result.data
      }
      catch (e) {
        return false
      }

    },
    async getSurveyStatus({ commit }) {
      const status = (await axios.get('/meta/surveystatus')).data
      commit('setSurveyStatus', status)
    },
    async savePatientSurvey({ dispatch }, patientSurvey) {
      try {
        const result = await axios.post('/surveys/'+ patientSurvey.survey_local_id + '/patients/', patientSurvey)
        dispatch("getSurveys")
        return result.data
      }
      catch (e) {
        return false
      }
    },
    async deletePatientSurvey({ dispatch }, patientSurvey) {
      try {
        await axios.delete('/surveys/'+ patientSurvey.survey_local_id + '/patients/' + patientSurvey.patient_id + '/' + patientSurvey.id)
      }
      catch (e) {
        return false
      }

      dispatch("getSurveys")
      return true
    },
    async getPatientSurveyTasks({ commit }, payload) {
      if(payload.survey_local_id !== null && payload.patient_id  !== null) {
        const tasks = (await axios.get('/surveys/'+ payload.survey_local_id + '/patients/' + payload.patient_id + '/tasks')).data
        commit('setPatientSurveyTasks', tasks)
      }
    },
    async savePatientSurveyTask(state, payload) {

      try {
        const result = await axios.post('/surveys/'+ payload.task.survey_local_id + '/patients/' + payload.task.patient_id + '/tasks', payload)
        return result.data
      }
      catch (e) {
        return false
      }

    },
    async deletePatientSurveyTask(state, payload) {

      try {
        await axios.delete('/surveys/'+ payload.task.survey_local_id + '/patients/' + payload.task.patient_id + '/tasks/' + payload.task.id)
        return true
      }
      catch (e) {
        return false
      }

    },
    async deleteSurveyTask(state, task) {

      try {
        await axios.delete('/surveytasks/'+task.id)
      }
      catch (e) {
        return false
      }
      return true

    },
    async getCurrentPatientSurveyTask({ commit }, payload) {
      const task = (await axios.get('/surveys/'+ payload.survey_local_id + '/patients/' + payload.patient_id + '/tasks/' + payload.id)).data
      commit('setCurrentPatientSurveyTask', task)
    },
    async getSurveysOfTherapyProtocol(state, therapy_protocol_id) {
      return (await axios.get('/surveys/therapyprotocols/' + therapy_protocol_id)).data
    },
    async saveSurveysOfTherapyProtocol(state, payload) {
      return (await axios.post('/surveys/therapyprotocols/' + payload.therapy_protocol_id, payload)).data
    },
    async saveSurveyTaskGroup(state, taskGroup) {
      try {
        const result = await axios.post('/surveytaskgroups/', taskGroup)
        return result.data
      }
      catch (e) {
        return false
      }
    },
    async getSurveyTaskGroups({ commit }, payload) {

      let param = '?'
      if(payload && payload.users) param += '&users='+payload.users.join(',')
      if(payload && payload.date) param += '&date='+payload.date

        const groups = (await axios.get('/surveytaskgroups/' + param)).data
        commit('setSurveyTaskGroups', groups)
    },
}

// mutations
const mutations = {
  setSurveys(state, surveys) {
    state.surveys = surveys
  },
  setSurveyTasks(state, surveytasks) {
    state.surveyTasks = surveytasks
  },
  setSurveyStatus(state, status) {
    state.surveyStatus = status
  },
  setPatientSurveyTasks(state, patienttasks) {
    state.patientSurveyTasks = patienttasks
  },
  setCurrentPatientSurveyTask(state, patienttask) {
    state.currentPatientSurveyTask = patienttask
  },
  setSurveyActivities(state, activities) {
    state.surveyActivities = activities
  },
  setSurveyTaskGroups(state, groups) {
    state.surveyTaskGroups = groups
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
