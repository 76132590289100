import axios from 'axios'

// initial state
const state = () => ({
    cro: [],
})

// getters
const getters = {
    cro: ({ cro }) => cro,
}

// actions
const actions = {
    async getCRO({ commit }) {
        const cro = (await axios.get('/meta/cro/')).data
        commit('setCro', cro)
    },
    async saveCRO(state, croData) {

        try {
            const result = await axios.post('/meta/cro', croData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteCRO(state, croData) {

        try {
            const result = await axios.delete('/meta/cro/'+croData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setCro(state, cro) {
        state.cro = cro
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
