<template>
  <div v-if="tableData">

    <el-table
      ref="evaluationstable"
      :data="tableData"
      :default-sort = "{prop: 'date', order: 'ascending'}"
      style="width: 100%"
      highlight-current-row
      @row-click="onRowClick"
      stripe>
      <el-table-column
        prop="date"
        label="Datum"
        width="100"
        sortable>
        <template #default="scope">
          <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="meta.recist.title"
        label="Recist / Beurteilung"
        width="150">
        <template #default="scope">
          <a :title="scope.row.meta.recist.title">{{scope.row.meta.recist.code}}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="result"
        label="Befunde">
      </el-table-column>
      <el-table-column
        prop="therapy_plan"
        label="Prozedere">
      </el-table-column>
      <el-table-column
        width="70">
        <template #default="scope">
          <div class="text-right">

            <el-popconfirm
              title="Sie möchten diese Verlaufsbeurteilung löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "ProgressEvaluationTable",
  props: {
      data: Array,
      expandable: Boolean,
      clickable: Boolean,
      deleteable: Boolean
  },
  emits: [
    'onRowDelete',
    'onRowClick'
  ],
  components: {

  },
  data() {
    return {
      rowExpandable: this.expandable || false,
      rowClickable: this.clickable || false,
      rowDeleteable: this.deleteable || false,
      tableData: this.data
    }
  },
  mounted: function () {
  },
  /*
  computed: {
    var: function () {
    }
  }
  */
  watch: {
    data: function (val) {
      this.tableData = val
    }
  },
  methods: {
    onRowDeleteConfirmed(row) {
      this.$emit('onRowDelete', row)
    },
    onRowClick(row) {
      if(this.rowClickable) {
        this.$emit('onRowClick', row)
      }
    },
    onRowRightClick(row) {
      if(this.rowExpandable) {
        this.$refs.evaluationstable.setCurrentRow(row)
        this.$refs.evaluationstable.toggleRowExpansion(row)
      }
    },
    onRowExpand(row) {
      this.$refs.evaluationstable.setCurrentRow(row)
    },
    setCurrentRow(row) {
      this.$refs.evaluationstable.setCurrentRow(row)
    }

  }
}
</script>
<style scoped>
</style>
