import axios from 'axios'

// initial state
const state = () => ({
    histologygroups: [],
})

// getters
const getters = {
    histologygroups: ({ histologygroups }) => histologygroups,
}

// actions
const actions = {
    async getHistologyGroups({ commit }) {
        const histologygroups = (await axios.get('/meta/histologygroups/')).data
        commit('setHistologyGroups', histologygroups)
    }
}

// mutations
const mutations = {
    setHistologyGroups(state, histologygroups) {
        state.histologygroups = histologygroups
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
