<template>
  <div v-if="currentPatient">
    <diagnosis-table :data="currentPatient.diagnosis" :expandable="true" :clickable="true"></diagnosis-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DiagnosisTable from "@/components/parts/DiagnosisTable";

export default {
  name: "PatientDiagnosis",
  props: [],
  components: {
    DiagnosisTable
  },
  data () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters('patients', ['currentPatient'])
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */

  methods: {

  }
}
</script>
<style scoped>
</style>
