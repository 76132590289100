<template>
  <div>
    <el-dialog v-if="currentFinding"
               :title="('Befund vom ' + $filters.germanDate(currentFinding.date) + ' bearbeiten')"
               width="75%"
               v-model="showForm"
               :before-close="closeDialog">
      <patient-finding-form @after-save="closeDialog" :id="currentFinding.id"></patient-finding-form>
    </el-dialog>

  </div>
</template>

<script>
import PatientFindingForm from "./PatientFindingForm";

export default {
  name: "PatientFindingFormModal",
  props: ['modelValue', 'finding'],
  components: {PatientFindingForm},
  data() {
    return {
      currentFinding: null,
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'finding': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentFinding = this.finding
    },
    closeDialog() {
      this.showForm = false
      this.currentFinding = null
      this.$emit('update:modelValue', false)
    },
  }
}
</script>
<style scoped>
</style>
