import axios from 'axios'

// initial state
const state = () => ({
    therapies: [],
    therapiesForForm: []
})

// getters
const getters = {
    therapies: ({ therapies }) => therapies,
}

// actions
const actions = {
    async getTherapies({ commit }, payload) {

        let param = '?'
        if(payload && payload.diagnosis) param += '&diagnosis='+payload.diagnosis
        if(payload && payload.treatmentsituation) param += '&treatmentsituation='+payload.treatmentsituation
        if(payload && payload.biomarker) param += '&biomarker='+payload.biomarker
        if(payload && payload.protocol) param += '&protocol='+payload.protocol
        if(payload && payload.only_protocol_own) param += '&only_protocol_own=1'
        if(payload && payload.therapies) param += '&therapies=' + payload.therapies.join(',')

        const therapies = (await axios.get('/therapies/'+param)).data
        if(payload && payload.therapiesForForm) {
          commit('setTherapiesForForm', therapies)
        }
        else {
          commit('setTherapies', therapies)
        }
    },
    async saveTherapyToPatient(state, patientTherapy) {
        let result

        const patient_id = patientTherapy.therapy.patient ? patientTherapy.therapy.patient.id : patientTherapy.therapy.patient_id
        if(patientTherapy.therapy.id) {
            result = (await axios.put('/patients/' + patient_id + '/therapies', patientTherapy)).data
        }
        else {
            result = (await axios.post('/patients/' + patient_id + '/therapies', patientTherapy)).data
        }
        return result
    },
    async deleteTherapyOfPatient(state, patientTherapy) {
        const patient_id = patientTherapy.patient ? patientTherapy.patient.id : patientTherapy.patient_id
        return (await axios.delete('/patients/' + patient_id + '/therapies/'+patientTherapy.id)).data
    }
}

// mutations
const mutations = {
    setTherapies(state, therapies) {
      state.therapies = therapies
    },
    setTherapiesForForm(state, therapies) {
      state.therapiesForForm = therapies
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
