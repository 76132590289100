<template>
  <div class="app">

    <el-dialog
        v-model="openDialog.exportPatientAll"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="Patienten PDF Download"
        width="30%"
    >
      <span>Der Export wurde fertiggestellt. Sie können die PDF Datei nun herunterladen.</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="openDialog.exportPatientAll = false">Abbrechen</el-button>
        <el-button type="primary" @click="onExportPatientAllDownload">Download</el-button>
      </span>
      </template>
    </el-dialog>


    <el-dialog
      v-model="openDialog.exportDatabase"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="Datenbank SQL Download"
      width="30%"
    >
      <span>Der Export wurde fertiggestellt. Sie können die SQL Datei nun herunterladen.</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="openDialog.exportDatabase = false">Abbrechen</el-button>
        <el-button type="primary" @click="onExportDatabaseDownload">Download</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="openDialog.syncDatabase"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="Knowledgebase Synchronisation"
      width="30%"
    >
      <span>Die Synchronisation wurde fertiggestellt.<br/>Ihre DACY-Knowledgebase ist nun auf dem neusten Stand.</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="openDialog.syncDatabase = false">OK</el-button>
      </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="openDialog.syncDatabaseError"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="Knowledgebase Synchronisation"
      width="30%"
    >
      <span>Fehler bei der Synchronisation. Ihre DACY-Knowledgebase konnte nicht aktualisiert werden.</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="openDialog.syncDatabaseError = false">OK</el-button>
      </span>
      </template>
    </el-dialog>

    <router-view></router-view>

  </div>
</template>

<script>
import DacyServer from "@/dacyserver";
import axios from "axios";

export default {
  name: 'App',
  data () {
    return {
      openDialog: {
        exportPatientAll: false,
        exportDatabase: false,
        syncDatabase: false,
        syncDatabaseError: false
      }
    }
  },
  mounted: async function () {

  },
  methods: {
    openNewWindow(url) {
      this.$router.push(url)
      //window.open(url, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
    },
    closeWindow() {
      window.close()
    },
    onExportPatientAllStarted() {

      this.$messageBox({
        message: "Der PDF Export wurde gestartet. Sie bekommen eine erneute Meldung nach Fertigstellung. Sie können derweil DACY weiter nutzen.",
        type: 'success'
      });

    },
    onExportPatientAllFinished() {
      this.openDialog.exportPatientAll = true
    },
    async onExportPatientAllDownload() {
      this.openDialog.exportPatientAll = false

      const signResult = await axios.get('/sign/export/patients/pdf/all/download')
      if(signResult) {
        const pdfUrl = DacyServer.getInstance().getURL() + signResult.data
        window.open(pdfUrl, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }
    },
    onExportDatabaseStarted() {

      this.$messageBox({
        message: "Der Datenbank Export wurde gestartet. Sie bekommen eine erneute Meldung nach Fertigstellung. Sie können derweil DACY weiter nutzen.",
        type: 'success'
      });

    },
    onExportDatabaseFinished() {
      this.openDialog.exportDatabase = true
    },
    async onExportDatabaseDownload() {
      this.openDialog.exportDatabase = false

      const signResult = await axios.get('/sign/export/database/download')
      if(signResult) {
        const sqlUrl = DacyServer.getInstance().getURL() + signResult.data
        window.open(sqlUrl, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
      }

    },
    onSyncDatabaseFinished() {
      this.openDialog.syncDatabase = true
    },
    onSyncDatabaseError() {
      this.openDialog.syncDatabaseError = true
    },
    setSocketListener() {

      const socket = require('./socket')
      socket.default.on('export-patient-all-started', () => {
        this.onExportPatientAllStarted()
      })
      socket.default.on('export-patient-all-finished', () => {
        this.onExportPatientAllFinished()
      })
      socket.default.on('export-database-started', () => {
        this.onExportDatabaseStarted()
      })
      socket.default.on('export-database-finished', () => {
        this.onExportDatabaseFinished()
      })
      socket.default.on('sync-database-finished', () => {
        this.onSyncDatabaseFinished()
      })
      socket.default.on('sync-database-error', () => {
        this.onSyncDatabaseError()
      })

    }
  }
}
</script>

<style>
:root {
  --el-color-primary: #e6a23c;
  --el-card-border-color: #EEE;
}

.el-button--default {
  --el-button-active-border-color: #900;
}

.el-button--default:active {
  color:#ffc563 !important;
  border-color:#ffc563 !important;
}

.el-button--primary {
  --el-button-background-color: #e6a23c;
  --el-button-border-color: #e6a23c;
  --el-button-hover-color: #f4a926;
  --el-button-active-background-color: #f19f1e;
  --el-button-active-border-color:#ffc563;
  --el-button-active-font-color:#fff;
}

body {
  margin: 0;
  padding: 0;
  background-color:#EEE;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-right {
  text-align: right;
}
.background-primary {
  background-color:#f39200;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-alert .el-alert__description {
  font-size:0.9em;
}

.el-card__header {
  padding: 0px;
}

.el-popper {
  background-color:#555;
  color:#FFF;
  padding:3px;
  font-size: 0.7em;
}

.el-select__popper {
  padding:0px !important;
}

.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
}
/*
.el-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  overflow: auto;
}

*/

.el-dialog__body {
  padding-top: 0px;
}

.el-table__expanded-cell td {
 border-bottom: none;
}

.customtable {
  width:100%;
}
.customtable tr {
  border:none;
}
.customtable td,
.customtable th{
  padding:5px;
}
.customtable th {
  font-weight:bold;
}
.customtable_wrapper:nth-child(even),
.customtable_wrapper:nth-child(even) .customtable th,
.customtable_wrapper:nth-child(even) .customtable td{
  background-color: #F5F5F5;
}
.clickable_row {
  cursor:pointer;
  text-decoration: underline;
}
.clickable_row:hover {
  background-color:#ecf5ff;
}
td td .customtable_wrapper {
  margin-left:1em;
  margin-bottom:1em;
}
.expand-box {
  border-left:5px solid #ccc;
  border-bottom:1px solid #ccc
}

.w-175px {
  width:175px;
}

.w-160px {
  width:160px;
}
</style>
