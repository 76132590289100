import axios from 'axios'

// initial state
const state = () => ({
    tnmOptions: []
})

// getters
const getters = {
  tnmOptions: ({ tnmOptions }) => tnmOptions,
}

// actions
const actions = {
    async getTNMOptions({ commit }, diagnosis_id) {
      const tnmOptions = (await axios.get('/meta/tnm?diagnosis_id='+diagnosis_id)).data
      commit('setTnmOptions', tnmOptions)
    },
    async getUICCStadium(state, payload) {

      let param = '?'
      param += 'classification_id='+payload.classification_id
      param += '&T='+payload.T
      param += '&N='+payload.N
      param += '&M='+payload.M

      return (await axios.get('/meta/tnm/uiccstadium' + param)).data
    }
}

// mutations
const mutations = {
  setTnmOptions(state, tnmOptions) {
        state.tnmOptions = tnmOptions
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
