import axios from 'axios'

// initial state
const state = () => ({
    drugs: [],
})

// getters
const getters = {
  drugs: ({ drugs }) => drugs,
}

// actions
const actions = {
    async getDrugs({ commit }) {

        const drugs = (await axios.get('/meta/drugs')).data
        commit('setDrugs', drugs)
    }
}

// mutations
const mutations = {
  setDrugs(state, drugs) {
        state.drugs = drugs
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
