<template>
  <el-row>
    <el-col :span="15">
      <h6>{{label_value}}</h6>
      <el-date-picker
        class="w-100"
        v-model="value"
        type="date"
        format="DD.MM.YYYY"
        value-format="YYYY-MM-DD"
        placeholder="Datum auswählen">
      </el-date-picker>
    </el-col>
    <el-col :span="8" :offset="1">
      <h6>{{label_value_precision}}</h6>
      <el-select v-model="value_precision" class="w-100">
        <el-option label="Tag" value="d"></el-option>
        <el-option label="Monat" value="m"></el-option>
        <el-option label="Jahr" value="y"></el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "DatePickerPrecision",
  props: ['modelValue', 'precision', 'label_date', 'label_precision'],
  emits: ['update:modelValue', 'update:precision'],
  components: {

  },
  data() {
    return {
      label_value: this.label_date || 'Datum',
      label_value_precision: this.label_precision || 'Genauigkeit'
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    value_precision: {
      get() {
        return this.precision
      },
      set(value) {
        this.$emit('update:precision', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
