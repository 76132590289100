// initial state
const state = () => ({
        patient_therapy_completed_states: [
            {
                id: null,
                title: "Laufend"
            },
            {
                id: 1,
                title: "Beendet"
            },
            {
                id: 2,
                title: "Geplant"
            }
        ],
})

// getters
const getters = {
    patient_therapy_completed_states: ({ patient_therapy_completed_states }) => patient_therapy_completed_states,
}

// actions
const actions = {
}

// mutations
const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
