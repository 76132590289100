import axios from 'axios'

// initial state
const state = () => ({
    therapylocations: [],
})

// getters
const getters = {
    therapylocations: ({ therapylocations }) => therapylocations,
}

// actions
const actions = {
    async getTherapyLocations({ commit }) {
        const therapylocations = (await axios.get('/meta/therapylocations/')).data
        commit('setTherapyLocations', therapylocations)
    },
    async saveTherapyLocation(state, therapyLocationData) {

        try {
            const result = await axios.post('/meta/therapylocations', therapyLocationData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteTherapyLocation(state, therapyLocationData) {

        try {
            const result = await axios.delete('/meta/therapylocations/'+therapyLocationData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setTherapyLocations(state, therapylocations) {
        state.therapylocations = therapylocations
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
