import axios from 'axios'

// initial state
const state = () => ({
    causalities: []
})

// getters
const getters = {
  causalities: ({ causalities }) => causalities,
}

// actions
const actions = {
    async getCausalities({ commit }) {
        const causalities = (await axios.get('/meta/causalities')).data
        commit('setCausalities', causalities)
    }
}

// mutations
const mutations = {
  setCausalities(state, causalities) {
        state.causalities = causalities
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
