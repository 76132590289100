import axios from 'axios'

// initial state
const state = () => ({
    tumorcenter: [],
})

// getters
const getters = {
    tumorcenter: ({ tumorcenter }) => tumorcenter,
}

// actions
const actions = {
    async getTumorCenter({ commit }) {
        const tumorcenter = (await axios.get('/meta/tumorcenter/')).data
        commit('setTumorCenter', tumorcenter)
    },
    async saveTumorCenter(state, tumorCenterData) {

        try {
            const result = await axios.post('/meta/tumorcenter', tumorCenterData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteTumorCenter(state, tumorCenterData) {

        try {
            const result = await axios.delete('/meta/tumorcenter/'+tumorCenterData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setTumorCenter(state, tumorcenter) {
        state.tumorcenter = tumorcenter
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
