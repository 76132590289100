import { createStore, createLogger } from 'vuex'
import patients from './modules/patients'
import devices from './modules/devices'
import diagnosis from './modules/diagnosis'
import treatmentsituations from './modules/treatmentsituations'
import biomarker from './modules/biomarker'
import therapies from './modules/therapies'
import histologygroups from './modules/histologygroups'
import diagnosismetastasis from './modules/diagnosismetastasis'
import diagnosticmethods from './modules/diagnosticmethods'
import doctors from './modules/doctors'
import therapyunits from './modules/therapyunits'
import recist from './modules/recist'
import patient_progress_evaluation_completed_states from './modules/patient_progress_evaluation_completed_states'
import patient_therapy_completed_states from './modules/patient_therapy_completed_states'
import practitioner from './modules/practitioner'
import deathcauses from "./modules/deathcauses";
import therapylocations from "./modules/therapylocations";
import tumorcenter from "./modules/tumorcenter";
import sideeffecttypes from "./modules/sideeffecttypes";
import causalities from "./modules/causalities";
import surveys from "./modules/surveys";
import subsidiaries from "./modules/subsidiaries";
import protocols from "./modules/protocols";
import drugs from "./modules/drugs";
import cancerregistry from "./modules/cancerregistry";
import config from "./modules/config";
import users from "./modules/users";
import protocolown from "./modules/protocolown";
import cro from "./modules/cro";
import therapyconcepts from "./modules/therapyconcepts";
import filecardtypes from "./modules/filecardtypes";
import TNM from "./modules/TNM";
import diagnosis_modules from "./modules/diagnosis_modules";
import ICD from "./modules/ICD";

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    modules: {
        patients,
        devices,
        diagnosis,
        treatmentsituations,
        biomarker,
        therapies,
        histologygroups,
        diagnosismetastasis,
        diagnosticmethods,
        doctors,
        therapyunits,
        recist,
        patient_progress_evaluation_completed_states,
        patient_therapy_completed_states,
        practitioner,
        deathcauses,
        therapylocations,
        tumorcenter,
        sideeffecttypes,
        causalities,
        surveys,
        subsidiaries,
        protocols,
        drugs,
        cancerregistry,
        config,
        users,
        protocolown,
        cro,
        therapyconcepts,
        TNM,
        filecardtypes,
        diagnosis_modules,
        ICD
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
