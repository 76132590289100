<template>

  <div v-if="currentTask">

        <el-row class="mt-4">
          <el-col :span="12">
            <h6>Aufgabe</h6>
            <el-select v-model="currentTask.survey_task_id" filterable class="w-100">
              <el-option
                v-for="item in surveyTasks"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="3" :offset="1">
            <h6>Nummer</h6>
            <el-input
              class="w-100"
              v-model="currentTask.number">
            </el-input>
          </el-col>
          <el-col :span="7" :offset="1">
            <h6>Zu erledigen am</h6>
            <el-date-picker
              class="w-100"
              v-model="currentTask.date"
              type="date"
              format="DD.MM.YYYY"
              value-format="YYYY-MM-DD"
              placeholder="Datum auswählen">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row class="mt-4">
          <el-col :span="24">
            <div class="mb-4" v-if="currentSurveyTask && currentSurveyTask.description != ''">
              <el-alert type="info" :description="currentSurveyTask.description" :closable="false"></el-alert>
            </div>
            <h6>Bemerkung</h6>
            <el-input type="textarea" v-model="currentTask.description" rows="5"></el-input>
          </el-col>
        </el-row>

        <div v-if="currentTask.id && currentTask.id > 0">

        <el-radio-group v-model="form.mode" class="mt-4">
          <el-radio-button label="done" type="danger" border><i class="el-icon el-icon-check"></i> Aufgabe als erledigt markieren</el-radio-button>
          <el-radio-button label="move" border><i class="el-icon el-icon-d-arrow-right"></i> Aufgabe verschieben</el-radio-button>
        </el-radio-group>

          <el-row v-if="form.mode == 'done'" class="mt-4">
            <el-col :span="16">
              <h6>Wieviel Minuten haben Sie für die Erledigung dieser Aufgabe benötigt?</h6>
              <el-input v-model="currentTask.duration">
                <template #append>Minuten</template>
              </el-input>
            </el-col>
          </el-row>

          <el-row v-if="form.mode == 'move'" class="mt-4">
            <el-col :span="12">
              <h6 >Geben Sie bitte das neue Datum an</h6>
              <el-date-picker
                class="w-100"
                v-model="form.move_date"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>

              <h6 class="mt-4">Sollen Folgetermine gleicher Art ebenfalls verschoben werden?</h6>
              <el-radio-group v-model="form.move_future_tasks">
                <el-radio :label="true">Ja, ebenfalls verschieben</el-radio>
                <el-radio :label="false">Nein, nicht verschieben</el-radio>
              </el-radio-group>

            </el-col>
          </el-row>

        </div>

        <hr class="mt-3"/>
        <el-row>
          <el-col :span="16">
            <el-popconfirm
              title="Möchten Sie diese Aufgabe löschen?"
              confirmButtonText='Ja, löschen'
              confirmButtonType="danger"
              cancelButtonText='Nein'
              iconColor="red"
              @confirm="onDeleteTaskConfirmed">
              <template #reference>
                <el-button type="danger" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>
          </el-col>
          <el-col :span="8" class="text-right">
            <el-button type="warning" @click="onSavePatientSurveyTask">Aufgabe speichern</el-button>
          </el-col>
        </el-row>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "SurveyPatientTaskForm",
  props: ['surveytask'],
  data() {
    return {
      currentTask: null,
      isSaving: false,
      form: {
        mode:null,
        move_date: null,
        move_future_tasks: true
      }
    }
  },
  mounted: async function () {
    this.init()
  },
  computed: {
    ...mapGetters('surveys', ['surveyTasks', 'currentPatientSurveyTask']),
  },
  watch: {
    'surveytask': async function() {
      this.init()
    },
    'currentPatientSurveyTask': async function() {
      this.currentTask = _.cloneDeep(this.currentPatientSurveyTask)
    },
  },
  methods: {
    ...mapActions('surveys', ['getSurveyTasks', 'savePatientSurveyTask', 'deletePatientSurveyTask', 'getCurrentPatientSurveyTask']),
    async init() {

      if(this.surveytask.survey_local_id > 0) {
        await this.getSurveyTasks({ survey: this.surveytask.survey_local_id })
      }

      if(this.surveytask.id && this.surveytask.id > 0) {
        this.getCurrentPatientSurveyTask(this.surveytask)
      }
      else {
        this.currentTask = this.surveytask
      }

    },
    async onSavePatientSurveyTask() {
      const result = await this.savePatientSurveyTask( { form: this.form, task: this.currentTask } )
      if(result && result.id) {
        this.currentTask = result
      }
      this.$emit('after-save')
    },
    async onDeleteTaskConfirmed() {
      const result = await this.deletePatientSurveyTask( { form: this.form, task: this.currentTask } )
      if(result) {
        this.$emit('after-save')
      }
    }
  }
}
</script>
<style scoped>
</style>
