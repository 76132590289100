// initial state
const state = () => ({
        patient_progress_evaluation_completed_states: [
            {
                id: null,
                title: "Kein Ende"
            },
            {
                id: 1,
                title: "Ende PFS"
            },
            {
                id: 2,
                title: "Ende RFS"
            }
        ],
})

// getters
const getters = {
    patient_progress_evaluation_completed_states: ({ patient_progress_evaluation_completed_states }) => patient_progress_evaluation_completed_states,
}

// actions
const actions = {
}

// mutations
const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
