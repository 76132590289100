import axios from 'axios'

// initial state
const state = () => ({
    diagnosismetastasis: [],
})

// getters
const getters = {
    diagnosismetastasis: ({ diagnosismetastasis }) => diagnosismetastasis,
}

// actions
const actions = {
    async getDiagnosisMetastasis({ commit }) {
        const diagnosismetastasis = (await axios.get('/meta/diagnosis_metastasis/')).data
        commit('setDiagnosisMetastasis', diagnosismetastasis)
    }
}

// mutations
const mutations = {
    setDiagnosisMetastasis(state, diagnosismetastasis) {
        state.diagnosismetastasis = diagnosismetastasis
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
