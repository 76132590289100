import axios from 'axios'
import moment from "moment";

// initial state
const state = () => ({
    currentPatient: null
})

// getters
const getters = {
    currentPatient: ({ currentPatient }) => currentPatient,
    fullName: (state, { currentPatient }) => currentPatient.name + ", " + currentPatient.firstname,
    fullInfo: (state, { currentPatient }) => {
      const dead = (currentPatient.deathday !== null)
      const birthdayoffset = dead ? currentPatient.deathday : moment().format('YYYY-MM-DD')
      return "#"+currentPatient.id
        + " " + currentPatient.name
        + ", " + currentPatient.firstname
        + " (" + moment(currentPatient.birthday).format("DD.MM.YY")
        + ", " + moment(birthdayoffset).diff(moment(currentPatient.birthday), 'years') + " Jahre)"
        + (dead ? ' † ' : '')
    },
    unusedFindings: (state) => {
        if(!state.currentPatient.findings) return []

        let unusedFindings = []
        for(const finding of state.currentPatient.findings) {
            if(finding.diagnosis.length == 0 && finding.progress_evaluations.length == 0) {
                unusedFindings.push(finding)
            }
        }
        return unusedFindings
    },
}

// actions
const actions = {
    resetCurrentPatient({ commit }) {
        commit('setCurrentPatient', null)
    },
    async setCurrentPatient({ commit }, patientId) {

        if(state.currentPatient && patientId == state.currentPatient.id) return

        try {
            const result = await axios.get('/patients/'+patientId)
            commit('setCurrentPatient', result.data)
            return result.data
        }
        catch (e) {
            commit('setCurrentPatient', null)
            return null
        }
    },
    async reloadCurrentPatient( { commit, state }) {
        console.log("reload current patient " + state.currentPatient.id)
        const patient = (await axios.get('/patients/'+state.currentPatient.id)).data
        commit('setCurrentPatient', patient)
    },
    async saveNewPatient(state, patientData) {

        try {
            const result = await axios.post('/patients', patientData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async updatePatient(state, patientData) {

        try {
            patientData.birthday = moment(patientData.birthday).format("YYYY-MM-DD")
            patientData.deathday = patientData.deathday ? moment(patientData.deathday).format("YYYY-MM-DD") : null
            const result = await axios.put('/patients/' + patientData.id, patientData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deletePatient(state, patientData) {

        try {
            await axios.delete('/patients/' + patientData.id)
            return true
        }
        catch (e) {
            return false
        }

    },
    async saveProgressToPatient(state, patientProgress) {
        let result
        if(patientProgress.id) {
            result = (await axios.put('/patients/' + patientProgress.patient_id + '/progress', patientProgress)).data
        }
        else {
            result = (await axios.post('/patients/' + patientProgress.patient_id + '/progress', patientProgress)).data
        }
        return result
    },
    async deleteProgressOfPatient(state, patientProgress) {
        return (await axios.delete('/patients/' + patientProgress.patient_id + '/progress/'+patientProgress.id)).data
    },
    async deleteProgressEvaluationOfPatient(state, payload) {
        return (await axios.delete('/patients/' + payload.patient_id + '/progress/evaluation/'+payload.evaluation_id)).data
    },
    async updateProgressEvaluationsOfPatient(state, patientProgresses) {
        if(!patientProgresses.length) return
        return (await axios.put('/patients/' + patientProgresses[0].patient_id + '/progress/evaluations', patientProgresses)).data
    },
    async saveDiagnosisToPatient(state, patientDiagnosis) {
        let result
        if(patientDiagnosis.id) {
            result = (await axios.put('/patients/' + patientDiagnosis.patient_id + '/diagnosis', patientDiagnosis)).data
        }
        else {
            result = (await axios.post('/patients/' + patientDiagnosis.patient_id + '/diagnosis', patientDiagnosis)).data
        }
        return result
    },
    async deleteDiagnosisOfPatient(state, patientDiagnosis) {
        if(patientDiagnosis.id) {
            return await axios.delete('/patients/' + patientDiagnosis.patient_id + '/diagnosis/' + patientDiagnosis.id)
        }
    },
    async getDiagnosisOfPatient(state, ids) {
        let result = (await axios.get('/patients/' + ids.patient_id + '/diagnosis/' + ids.patient_diagnosis_id)).data
        return result
    },
    async getFindingOfPatient(state, ids) {
      let result = (await axios.get('/patients/' + ids.patient_id + '/findings/' + ids.finding_id)).data
      return result
    },
    async saveFindingsToPatient(state, patientFinding) {
        if(patientFinding.patient_id === null) return false
        return (await axios.put('/patients/' + patientFinding.patient_id + '/findings', patientFinding)).data
    },
    async deleteFindingOfPatient(state, payload) {
        return (await axios.delete('/patients/' + payload.patient_id + '/findings/'+payload.finding_id)).data
    },
    async saveSideEffectToPatient(state, patientSideEffect) {
      let result
      if(patientSideEffect.id) {
        result = (await axios.put('/patients/' + patientSideEffect.patient_id + '/sideeffects', patientSideEffect)).data
      }
      else {
        result = (await axios.post('/patients/' + patientSideEffect.patient_id + '/sideeffects', patientSideEffect)).data
      }
      return result
    },
    async getSideEffectOfPatient(state, ids) {
      let result = (await axios.get('/patients/' + ids.patient_id + '/sideeffects/' + ids.patient_sideeffect_id)).data
      return result
    },
    async deleteSideEffectOfPatient(state, currentSideEffect) {
      return await axios.delete('/patients/' + currentSideEffect.patient_id + '/sideeffects/'+currentSideEffect.id)
    },
    async saveFileCardToPatient(state, filecard) {
      let result = await axios.post('/patients/' + filecard.patient_id + '/filecards', filecard)
      return result
    },
    async deleteFileCardOfPatient(state, filecard) {
      if(filecard.id) {
        return await axios.delete('/patients/' + filecard.patient_id + '/filecards/' + filecard.id)
      }
    },
    async getFileCardsOfPatient(state, ids) {
      let result = (await axios.get('/patients/' + ids.patient_id + '/filecards/')).data
      return result
    },
    async saveEvaluationToPatient(state, evaluation) {
      let result = await axios.post('/patients/' + evaluation.patient_id + '/evaluations', evaluation)
      return result
    },
    async deleteEvaluationOfPatient(state, evaluation) {
      if(evaluation.id) {
        return await axios.delete('/patients/' + evaluation.patient_id + '/evaluations/' + evaluation.id)
      }
    },
    async getEvaluationsOfPatient(state, ids) {
      let result = (await axios.get('/patients/' + ids.patient_id + '/evaluations/')).data
      return result
    }
}

// mutations
const mutations = {
    setCurrentPatient(state, currentPatient) {
        console.log("current", currentPatient)
        state.currentPatient = currentPatient
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
