<template>
  <div v-if="currentPatient">
    <el-table
        :data="currentPatient.side_effects"
        :default-sort = "{prop: 'date_start', order: 'ascending'}"
        style="width: 100%"
        @row-click="onClickSideEffect"
        stripe>
      <el-table-column
          prop="date_start"
          label="Beginn"
          width="100"
          sortable>
        <template #default="scope">
          {{ $filters.germanDate(scope.row.date_start) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="date_end"
          label="Ende"
          width="100"
          sortable>
        <template #default="scope">
          <span v-if="scope.row.date_end">{{ $filters.germanDate(scope.row.date_end) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="meta.side_effect_type.title"
          label="Komplikation"
          width="300"
          sortable>
        <template #default="scope">
          <span v-if="scope.row.meta.side_effect_type">{{scope.row.meta.side_effect_type.title}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="Beschreibung">
        <template #default="scope">
          <span v-if="scope.row.progress && scope.row.progress[0]">{{scope.row.progress[0].description}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "PatientSideEffects",
  props: [],
  components: {
  },
  data () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters('patients', ['currentPatient'])
  },
  /*
  watch: {
    var: function (val) {
    }
  },
  */
  methods: {
    onClickSideEffect(row) {
      this.$router.push({name: 'edit_sideeffect', params: {id: row.patient_id, patient_sideeffect_id: row.id}})
    }
  }
}
</script>
<style scoped>
</style>
