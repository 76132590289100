import axios from 'axios'

// initial state
const state = () => ({
    subsidiaries: [],
})

// getters
const getters = {
  subsidiaries: ({ subsidiaries }) => subsidiaries,
}

// actions
const actions = {
    async getSubsidiaries({ commit }) {
        const subsidiaries = (await axios.get('/meta/subsidiaries/')).data
        commit('setSubsidiaries', subsidiaries)
    },
    async saveSubsidiary(state, subsidiaryData) {

        try {
            const result = await axios.post('/meta/subsidiaries', subsidiaryData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteSubsidiary(state, subsidiaryData) {

        try {
            const result = await axios.delete('/meta/subsidiaries/'+subsidiaryData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setSubsidiaries(state, subsidiaries) {
        state.subsidiaries = subsidiaries
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
