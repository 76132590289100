import axios from 'axios'

// initial state
const state = () => ({
    biomarker: [],
})

// getters
const getters = {
    biomarker: ({ biomarker }) => biomarker,
}

// actions
const actions = {
    async getBiomarker({ commit }) {
        const biomarker = (await axios.get('/meta/biomarker/')).data
        commit('setBiomarker', biomarker)
    }
}

// mutations
const mutations = {
    setBiomarker(state, biomarker) {
        state.biomarker = biomarker
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
