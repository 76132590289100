import axios from 'axios'

// initial state
const state = () => ({
    users: [],
  user_roles: []
})

// getters
const getters = {
  users: ({ users }) => users,
  user_roles: ({ user_roles }) => user_roles
}

// actions
const actions = {
    async getUsers({ commit }) {
        const users = (await axios.get('/users/')).data
        commit('setUsers', users)
    },
    async getUsersCro(state, payload) {
      const userCro = (await axios.get('/users/' + payload.user_id + '/cro/' + (payload.cro_id ? '?cro_id='+payload.cro_id : ''))).data
      return userCro
    },
    async getUserRoles({ commit }) {
      const roles = (await axios.get('/meta/roles')).data
      commit('setUserRoles', roles)
    },
    async saveUser(state, user) {

        try {
            const result = await axios.post('/users', user)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async saveUsersCro(state, payload) {

      try {
        await axios.post('/users/' + payload.user_id + '/cro', payload.cro)
        return true
      }
      catch (e) {
        return false
      }

    },
    async deleteUser(state, user) {

        try {
            const result = await axios.delete('/users/'+user.id)
            return result.data
        }
        catch (e) {
            return false
        }

    }
}

// mutations
const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setUserRoles(state, roles) {
    state.user_roles = roles
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
