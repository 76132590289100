<template>
  <div>

    <el-select
        class="w-75"
        v-model="currentPatientId"
        filterable
        default-first-option
        remote
        placeholder="Suche nach Patient"
        :remote-method="getPatients"
        :loading="loading">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "PatientSearch",
  props: ['patient_id', 'set_current_patient'],
  components: {
  },
  data () {
    return {
      result: [],
      loading: false,
      options: [],
      currentPatientId: null
    }
  },
  mounted: async function () {
    if(this.currentPatient) {
      await this.getPatients(this.currentPatient.id)
      this.currentPatientId = parseInt(this.currentPatient.id)
    }
  },

  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
  },

  watch: {
    currentPatient: async function(patient) {
      if(patient === null) {
        this.currentPatientId = null
      }
      else {
        if(patient.id != this.currentPatientId) {
          await this.getPatients(patient.id)
          this.currentPatientId = parseInt(patient.id)
        }
      }
    },
    patient_id: async function(val) {
      await this.getPatients(val)
      this.currentPatientId = parseInt(val)
    },
    currentPatientId: async function(val) {
      for(let i = 0; i < this.result.length; i++) {
        if(this.result[i].id == val) {

          if(this.set_current_patient !== undefined && this.set_current_patient === false) {
            this.$emit('onSelect', this.result[i])
          } else {
            await this.$store.dispatch('patients/setCurrentPatient', this.result[i].id)
            document.title = this.fullInfo
          }
          break
        }
      }
    }
  },

  methods: {
    async getPatients(query) {
      if (query !== '') {
        this.loading = true;
        this.result = (await this.axios.get('/patients/?search='+query)).data
        this.loading = false;
        this.options = this.result.map(item => {
          const dead = (item.deathday !== null)
          const birthdayoffset = dead ? item.deathday : moment().format('YYYY-MM-DD')
          return {
            value: item.id,
            label: "#"+item.id + ' ' + item.name + ', ' +item.firstname + ' (' + moment(item.birthday).format("DD.MM.YY") + ", " + moment(birthdayoffset).diff(moment(item.birthday), 'years') + ' Jahre)' + (dead ? ' † ' : '')
          };
        });

      } else {
        this.options = [];
      }
    }
}

}
</script>
<style scoped>
</style>
