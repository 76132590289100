import axios from 'axios'

// initial state
const state = () => ({
    deathcauses: [],
})

// getters
const getters = {
    deathcauses: ({ deathcauses }) => deathcauses,
}

// actions
const actions = {
    async getDeathcauses({ commit }) {
        const deathcauses = (await axios.get('/meta/deathcauses/')).data
        commit('setDeathcauses', deathcauses)
    },
}

// mutations
const mutations = {
    setDeathcauses(state, deathcauses) {
        state.deathcauses = deathcauses
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
