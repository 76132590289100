import axios from 'axios'

// initial state
const state = () => ({
    recist: [],
})

// getters
const getters = {
    recist: ({ recist }) => recist,
}

// actions
const actions = {
    async getRecist({ commit }) {
        const recist = (await axios.get('/meta/recist/')).data
        commit('setRecist', recist)
    }
}

// mutations
const mutations = {
    setRecist(state, recist) {
        state.recist = recist
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
