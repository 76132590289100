import axios from 'axios'

// initial state
const state = () => ({
    diagnosis: [],
    oncologyDiagnosis: []
})

// getters
const getters = {
    diagnosis: ({ diagnosis }) => diagnosis,
    oncologyDiagnosis: ({ oncologyDiagnosis }) => oncologyDiagnosis
}

// actions
const actions = {
    async getDiagnosis({ commit }) {
        const diagnosis = (await axios.get('/diagnosis/')).data
        commit('setDiagnosis', diagnosis)
    },
    async getDiagnosisById(state, diagnosis_id) {
      return (await axios.get('/diagnosis/' + diagnosis_id)).data
    },
    async getOncologyDiagnosis({ commit }) {
        const oncologyDiagnosis = (await axios.get('/diagnosis/?group=1')).data
        commit('setOncologyDiagnosis', oncologyDiagnosis)
    },
    async saveDiagnosisTNM(state, payload) {

      try {
        await axios.post('/diagnosis/' + payload.diagnosis_id + "/tnm", payload.tnm)
        return true
      }
      catch (e) {
        return false
      }

    }
}

// mutations
const mutations = {
    setDiagnosis(state, diagnosis) {
        state.diagnosis = diagnosis
    },
    setOncologyDiagnosis(state, oncologyDiagnosis) {
        state.oncologyDiagnosis = oncologyDiagnosis
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
