import axios from 'axios'

// initial state
const state = () => ({
    filecardtypes: [],
})

// getters
const getters = {
  filecardtypes: ({ filecardtypes }) => filecardtypes,
}

// actions
const actions = {
    async getFileCardTypes({ commit }) {
        const filecardtypes = (await axios.get('/meta/filecardtypes/')).data
        commit('setFileCardTypes', filecardtypes)
    },
    async saveFileCardType(state, filecardtypeData) {

        try {
            const result = await axios.post('/meta/filecardtypes', filecardtypeData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteFileCardType(state, filecardtypeData) {

        try {
            const result = await axios.delete('/meta/filecardtypes/'+filecardtypeData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
  setFileCardTypes(state, filecardtypes) {
        state.filecardtypes = filecardtypes
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
