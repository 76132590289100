<template>
  <div>

    <div v-if="currentFinding" class="mt-4">

      <el-tabs type="card" class="mt-3">
        <el-tab-pane label="Befunddaten">

          <el-row class="mt-1">

            <el-col :span="7">
              <h6>Datum des Befunds</h6>
              <el-date-picker
                class="w-100"
                v-model="currentFinding.date"
                type="date"
                format="DD.MM.YYYY"
                value-format="YYYY-MM-DD"
                placeholder="Datum auswählen">
              </el-date-picker>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>Diagnostisches Verfahren</h6>
              <el-select size="medium" class="w-100" v-model="currentFinding.diagnostic_method_id" filterable>
                <el-option
                  v-for="item in diagnosticmethods"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-col>

          </el-row>
          <el-row class="mt-4">

            <el-col :span="7">
              <h6>Diagnostiker</h6>
              <el-select size="medium" class="w-100" v-model="currentFinding.doctor_id" filterable>
                <el-option
                  v-for="item in doctors"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>klinische Einheit</h6>
              <el-select size="medium" class="w-100" v-model="currentFinding.therapy_unit_id" filterable>
                <el-option
                  v-for="item in therapyunits"
                  :key="item.id"
                  :label="item.code"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col :span="7">
              <h6>Befund-Kennung</h6>
              <el-input v-model="currentFinding.code"></el-input>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>ICD-O-3/T</h6>
              <el-input v-model="currentFinding.icd10"></el-input>
            </el-col>
            <el-col :span="7" :offset="1">
              <h6>ICD-O-3/M</h6>
              <el-input v-model="currentFinding.icdo3"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col>
              <h6>Bewertung</h6>
              <el-input v-model="currentFinding.evaluation" type="textarea"></el-input>
            </el-col>
          </el-row>
          <el-row class="mt-4">
            <el-col >
              <h6>Kommentar</h6>
              <el-input v-model="currentFinding.comment" type="textarea"></el-input>
            </el-col>

          </el-row>

        </el-tab-pane>
        <el-tab-pane label="Zugewiesene Diagnosen">

          <el-row class="mt-1">
            <el-col :span="24">
              <diagnosis-table :data="currentFinding.diagnosis" :deleteable="true" @on-row-delete="onDeleteDiagnosisFromFinding"></diagnosis-table>
            </el-col>
          </el-row>

          <el-row class="mt-4" v-if="currentPatient && selectableDiagnosis.length > 0">
            <el-col :span="10">
              Diagnose zuweisen:
              <el-select placeholder="Diagnose auswählen" size="medium" class="ml-3" v-model="selectedDiagnosis" filterable>
                <el-option
                  v-for="item in selectableDiagnosis"
                  :key="item.id"
                  :label="($filters.germanDate(item.date) + ', ' + (item.meta.diagnosis_metastasis ? item.meta.diagnosis_metastasis.title+', ' : '') + item.meta.diagnosis.title + ', ' + (item.comment || ''))"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="mt-4" v-else>
            <el-col :span="24">
              Es sind keine weiteren Diagnosen zur Zuweisung vorhanden
            </el-col>
          </el-row>

        </el-tab-pane>
        <el-tab-pane label="TNM">

          <div v-if="currentFinding.diagnosis.length > 0">
            <TNMExtract v-model="currentFinding.tnm" :diagnosis_id="(primaryDiagnosis ? primaryDiagnosis.diagnosis_id : null)" context="finding" :selectable_diagnosis="currentFinding.diagnosis"></TNMExtract>
          </div>
          <div v-if="currentFinding.diagnosis.length === 0">
            <el-alert type="warning">Bitte weisen Sie dem Befund zunächst eine Diagnose zu</el-alert>
          </div>

        </el-tab-pane>
        <el-tab-pane label="Zugewiesene Verlaufsbeurteilungen">

          <el-row class="mt-1">
            <el-col :span="24">
              <progress-evaluation-table :data="currentFinding.progress_evaluations" :deleteable="true" @on-row-delete="onDeleteProgressEvaluationFromFinding"></progress-evaluation-table>
            </el-col>
          </el-row>

          <el-row class="mt-4" v-if="currentPatient && selectableProgressEvaluations.length > 0">
            <el-col :span="10">
              Verlaufsbeurteilung zuweisen:
              <el-select placeholder="Verlaufsbeurteilung auswählen" size="medium" class="ml-3" v-model="selectedProgressEvaluation" filterable>
                <el-option
                  v-for="item in selectableProgressEvaluations"
                  :key="item.id"
                  :label="($filters.germanDate(item.date) + ', ' + item.progress_title + (item.result ? ', ' + item.result : ''))"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="mt-4" v-else>
            <el-col :span="24">
              Es sind keine Verlaufsbeurteilungen zur Zuweisung vorhanden
            </el-col>
          </el-row>

        </el-tab-pane>
      </el-tabs>

      <div class="mt-4 text-right">
        <el-button type="warning" @click="onSaveFinding({resetForm: false})">Befund speichern</el-button>
      </div>

    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import DiagnosisTable from "@/components/parts/DiagnosisTable";
import ProgressEvaluationTable from "@/components/parts/ProgressEvaluationTable";
import TNMExtract from "../parts/TNMExtract.vue";
const _ = require('lodash');

export default {
  name: "PatientFindingForm",
  props: ['id'],
  components: {TNMExtract, ProgressEvaluationTable, DiagnosisTable},
  data() {
    return {
      newFindingTemplate: {
        id: 0,
        date: moment().format("YYYY-MM-DD"),
        diagnosis: [],
        progress_evaluations: [],
        patient_id: null,
        meta: {},
        tnm: {
          tnm: ''
        }
      },
      currentFinding: null,
      selectedDiagnosis: null,
      selectableDiagnosis: [],
      selectedProgressEvaluation: null,
      selectableProgressEvaluations: [],
    }
  },
  mounted: async function () {

    await this.getDoctors()
    await this.getTherapyUnits()
    await this.getDiagnosticMethods()
    this.loadFinding()

  },
  computed: {
    ...mapGetters('patients', ['currentPatient']),
    ...mapGetters('doctors', ['doctors']),
    ...mapGetters('therapyunits', ['therapyunits']),
    ...mapGetters('diagnosticmethods', ['diagnosticmethods']),
    primaryDiagnosis: function () {
      if(this.currentFinding && this.currentFinding.diagnosis.length > 0) {
        return _.find(this.currentFinding.diagnosis, (d) => d.is_primary == true)
      }
      return null
    },
  },
  watch: {
    'id': function() {
      this.loadFinding()
    },
    'selectedDiagnosis': function(diagnosis) {

      if(diagnosis) {
        const exist = _.find(this.currentFinding.diagnosis, (o) => o.id ==diagnosis.id)
        if(exist) return

        let allDiagnosis = _.cloneDeep(this.currentFinding.diagnosis)
        allDiagnosis.push(diagnosis)

        this.currentFinding.diagnosis = allDiagnosis
        this.selectedDiagnosis = null
      }

    },
    'selectedProgressEvaluation': function(evaluation) {

      if(evaluation) {
        const exist = _.find(this.currentFinding.progress_evaluations, (o) => o.id ==evaluation.id)
        if(exist) return

        let allEvaluations = _.cloneDeep(this.currentFinding.progress_evaluations)
        allEvaluations.push(evaluation)

        this.currentFinding.progress_evaluations = allEvaluations
        this.selectedProgressEvaluation = null
      }

    },
  },
  methods: {
    ...mapActions('patients', ['saveFindingsToPatient', 'deleteFindingOfPatient', 'reloadCurrentPatient', 'getFindingOfPatient']),
    ...mapActions('diagnosis', ['getDiagnosis']),
    ...mapActions('doctors', ['getDoctors']),
    ...mapActions('therapyunits', ['getTherapyUnits']),
    ...mapActions('diagnosticmethods', ['getDiagnosticMethods']),
    async loadFinding() {
      if(!this.currentPatient.id) return

      if(this.id > 0) {
        this.currentFinding = await this.getFindingOfPatient({ patient_id: this.currentPatient.id, finding_id: this.id })
      }
      else {
        this.currentFinding = _.cloneDeep(this.newFindingTemplate)
        this.currentFinding.patient_id = this.currentPatient.id
      }

      this.selectableDiagnosis = _.cloneDeep(this.currentPatient.diagnosis)

      const patientProgress = _.cloneDeep(this.currentPatient.progress)
      for(const progress of patientProgress) {
        for(const evaluation of progress.evaluations){
          evaluation.progress_title = progress.title
          this.selectableProgressEvaluations.push(evaluation)
        }
      }

    },
    async onDeleteFinding(finding) {
      if(finding.id > 0) {
        await this.deleteFindingOfPatient({
          patient_id: finding.patient_id,
          finding_id: finding.id
        })
      }
      if(this.currentFinding && this.currentFinding.id && this.currentFinding.id == finding.id) this.currentFinding = null

    },
    onDeleteProgressEvaluationFromFinding(evaluation){
      this.currentFinding.progress_evaluations = _.filter(this.currentFinding.progress_evaluations, (o) => o.id !== evaluation.id)
    },
    onDeleteDiagnosisFromFinding(diagnosis) {
      this.currentFinding.diagnosis = _.filter(this.currentFinding.diagnosis, (o) => o.id !== diagnosis.id)
    },
    async onSaveFinding() {

      delete this.currentFinding.meta

      this.currentFinding.tnm.date = this.currentFinding.date
      if(this.currentFinding.diagnosis && this.currentFinding.diagnosis.length === 1) this.currentFinding.tnm.patient_diagnosis_id = this.currentFinding.diagnosis[0].id

      const findingResult = await this.saveFindingsToPatient(this.currentFinding)

      if(findingResult === false) {
        this.$notify({
          message: 'Befund wurden nicht gespeichert',
          type: 'danger',
          position: 'bottom-left'
        });
      }
      else {
        this.$emit('after-save')
        this.$notify({
          message: 'Befund wurden gespeichert',
          type: 'success',
          position: 'bottom-left'
        });

      }

    }
  }
}
</script>
<style scoped>
</style>
