<template>
  <div v-if="tableData">

    <el-table
        ref="diagnosisTable"
        :data="tableData"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowClick"
        @row-contextmenu="onRowRightClick"
        @expand-change="onRowExpand"
        stripe>
      <el-table-column v-if="rowExpandable" type="expand">
        <template #default="scope">
          <div v-if="!scope.row.findings.length">Keine Befunde vorhanden</div>
          <div v-else :key="finding.id" v-for="finding in scope.row.findings" class="customtable_wrapper expand-box mb-3">
            <table class="customtable" style="width:auto;">
              <tr>
                <th width="150">Befund-Datum</th>
                <th width="230">Diagnostisches Verfahren</th>
                <th>Bewertung</th>
              </tr>
              <tr>
                <td>{{ $filters.germanDate(finding.date) }}</td>
                <td><span v-if="finding.meta.diagnosticmethod">{{finding.meta.diagnosticmethod.title}}</span></td>
                <td>{{finding.evaluation}}</td>
              </tr>
            </table>
            <table class="customtable" style="width:auto;">
              <tr>
                <th width="150">Diagnostiker</th>
                <th width="230">klinische Einheit</th>
                <th width="150">Kennung</th>
                <th width="100">ICD10</th>
                <th width="100">ICD-O-3</th>
              </tr>
              <tr>
                <td><span v-if="finding.meta.doctor">{{finding.meta.doctor.title}}</span></td>
                <td><span v-if="finding.meta.therapyunit">{{finding.meta.therapyunit.code}}</span></td>
                <td>{{finding.code}}</td>
                <td width="100">{{finding.icd10}}</td>
                <td width="100">{{finding.icdo3}}</td>
              </tr>
            </table>
            <table v-if="finding.comment" class="customtable">
              <tr>
                <th>Kommentar</th>
              </tr>
              <tr>
                <td>{{finding.comment}}</td>
              </tr>
            </table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="date"
          label="Datum"
          width="100">
        <template #default="scope">
          <span style="white-space: nowrap">{{ $filters.germanDate(scope.row.date, false, scope.row.date_precision) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="diagnosis.title"
          label="Diagnose">
        <template #default="scope">

          <div v-if="scope.row.primary_diagnosis_id !== null" class="d-inline-block el-icon-d-arrow-right">&nbsp;</div>

          <el-tooltip
            content="Diagnose gesichert"
            placement="bottom"
            v-if="scope.row.certainty == 4 || scope.row.certainty == 5 || scope.row.certainty == 6 || scope.row.certainty == 7">
            <el-tag size="mini" type="warning" class="mr-1" >P</el-tag>
          </el-tooltip>
          <el-tooltip
            content="klinische Diagnose"
            placement="bottom"
            v-if="scope.row.certainty == 1 || scope.row.certainty == 2">
            <el-tag size="mini" type="warning" class="mr-1" >C</el-tag>
          </el-tooltip>
          <el-tooltip
            content="Verdacht auf"
            placement="bottom"
            v-if="scope.row.certainty == 'V'">
            <el-tag size="mini" type="warning" class="mr-1" >S</el-tag>
          </el-tooltip>

          <span v-if="scope.row.meta.diagnosis_metastasis">{{scope.row.meta.diagnosis_metastasis.title}}, {{scope.row.meta.diagnosis.title}}</span>
          <span v-else-if="scope.row.meta.diagnosis">{{scope.row.meta.diagnosis.code}}, {{scope.row.meta.diagnosis.title}}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="TNM / Stadium">
        <template #default="scope">
          <span v-for="tnm in scope.row.tnm" :key="tnm.id">
            <div v-if="tnm.is_cancerregistry">{{tnm.tnm}}</div>
          </span>
        </template>
      </el-table-column>
      <el-table-column
          prop="comment"
          label="Beschreibung / Kommentar">
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowEditClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          v-if="rowDeleteable"
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten die Zuweisung zur Diagnose vom ' + $filters.germanDate(scope.row.date) + ' löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "DiagnosisTable",
  props: {
      data: Array,
      expandable: Boolean,
      clickable: Boolean,
      deleteable: Boolean
  },
  emits: [
    'onRowDelete'
  ],
  components: {

  },
  data() {
    return {
      rowExpandable: this.expandable || false,
      rowClickable: this.clickable || false,
      rowDeleteable: this.deleteable || false,
      tableData: this.data
    }
  },
  mounted: function () {
  },
  /*
  computed: {
    var: function () {
    }
  }
  */
  watch: {
    data: function (val) {
      this.tableData = val
    }
  },
  methods: {
    onRowDeleteConfirmed(row) {
      this.$emit('onRowDelete', row)
    },
    gotoEditDiagnosisView(row) {
      this.$router.push({name: 'edit_diagnosis', params: {id: row.patient_id, patient_diagnosis_id: row.id}})
    },
    onRowEditClick(row) {
      this.gotoEditDiagnosisView(row)
    },
    onRowClick(row) {
      if(this.rowClickable) {
        this.gotoEditDiagnosisView(row)
      }
    },
    onRowRightClick(row) {
      if(this.rowExpandable) {
        this.$refs.diagnosisTable.setCurrentRow(row)
        this.$refs.diagnosisTable.toggleRowExpansion(row)
      }
    },
    onRowExpand(row) {
      this.$refs.diagnosisTable.setCurrentRow(row)
    }
}
}
</script>
<style scoped>
</style>
