import axios from 'axios'

// initial state
const state = () => ({
    protocols: [],
})

// getters
const getters = {
  protocols: ({ protocols }) => protocols,
}

// actions
const actions = {
    async getProtocols({ commit }, payload) {

        let drugs = []
        let ids = []
        if(payload && payload.drug1) drugs.push(payload.drug1)
        if(payload && payload.drug2) drugs.push(payload.drug2)
        if(payload && payload.drug3) drugs.push(payload.drug3)
        if(payload && payload.ids) ids.push(payload.ids)
        const param = '?drugs='+drugs.join(',') + '&ids='+ids.join(',')

        const protocols = (await axios.get('/protocols/'+param)).data
        commit('setProtocols', protocols)
    }
}

// mutations
const mutations = {
  setProtocols(state, protocols) {
        state.protocols = protocols
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
