// initial state
const state = () => ({
    modules_enabled: {
      cancerregistry:  (process.env.VUE_APP_CANCERREGISTRY_MODULE_ENABLED ? (process.env.VUE_APP_CANCERREGISTRY_MODULE_ENABLED == 'true' ? true : false) : false),
      survey:  (process.env.VUE_APP_SURVEY_MODULE_ENABLED ? (process.env.VUE_APP_SURVEY_MODULE_ENABLED == 'true' ? true : false) : false),
      knowledgebase:  (process.env.VUE_APP_KNOWLEDGEBASE_MODULE_ENABLED ? (process.env.VUE_APP_KNOWLEDGEBASE_MODULE_ENABLED == 'true' ? true : false) : false)
    },
    route_name_after_login: process.env.VUE_APP_ROUTE_NAME_AFTER_LOGIN ? process.env.VUE_APP_ROUTE_NAME_AFTER_LOGIN : 'dashboard'
})

// getters
const getters = {
  modules_enabled: ({ modules_enabled }) => modules_enabled,
  route_name_after_login: ({ route_name_after_login }) => route_name_after_login
}

// actions
const actions = {
}

// mutations
const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
