
<template>
  <el-alert show v-if="localTnm.patient_finding_id && context !== 'finding'" type="info" :closable="false">Diese TNM Daten sind aus einem Befund <span v-if="patientFinding && patientFinding.meta && patientFinding.meta.diagnosticmethod">({{patientFinding.meta.diagnosticmethod.title}})</span> vom {{ $filters.germanDate(localTnm.date) }}</el-alert>

  <el-row class="mt-4" v-if="context === 'finding' && selectable_diagnosis && selectable_diagnosis.length > 1">
    <el-col :span="12">
      <h6>Betreffende Diagnose</h6>
        <el-select placeholder="Diagnose auswählen" size="medium" class="w-100" v-model="localTnm.patient_diagnosis_id" filterable>
          <el-option
            v-for="item in selectable_diagnosis"
            :key="item.id"
            :label="($filters.germanDate(item.date) + ', ' + (item.meta.diagnosis_metastasis ? item.meta.diagnosis_metastasis.title+', ' : '') + item.meta.diagnosis.title + ', ' + (item.comment || ''))"
            :value="item.id">
          </el-option>
        </el-select>
    </el-col>
  </el-row>

  <el-row class="mt-4" v-if="context === 'diagnosis'">
      <el-col :span="10">
        <h6>Datum</h6>
        <el-date-picker
          v-model="localTnm.date"
          :disabled="localTnm.patient_finding_id && context !== 'finding'"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="13" :offset="1">
        <h6>Krebsregister <i class="icon el-icon-star-on"></i></h6>
        <el-checkbox v-model="localTnm.is_cancerregistry" @change="onCancerregistryChange">Ja, relevant für die Krebsregistermeldung</el-checkbox>
      </el-col>
  </el-row>

  <el-row  class="mt-4" v-if="tnmOptions.length > 1">
    <el-col :span="10">
      <h6>TNM Klassifikation</h6>
      <el-select v-model="tnmSelectedId" filterable class="w-100"  >
        <el-option
          v-for="item in tnmOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-col>
  </el-row>

  <el-row class="mt-4">
    <el-col :span="24">
      <h6>TNM Summenformel</h6>
      <el-row>
        <el-col :span="19">
          <el-input v-model="localTnm.tnm" ></el-input>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-button type="warning" @click="onExtractTNM" :disabled="(localTnm.tnm === '' || !localTnm.tnm)">Extrahieren</el-button>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-row class="mt-4" v-if="localTnm.tnm_extract">
    <el-col :span="8">
      <h3>-- T --</h3>

      <el-select v-model="localTnm.tnm_extract.T.value" filterable class="w-75 mb-3">
        <el-option
          v-for="item in (tnmSelectable.T ? tnmSelectable.T : [])"
          :key="item.id"
          :label="item.value_with_prefix"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-checkbox v-model="localTnm.tnm_extract.T.clinical" class="d-block p-0 m-0 h-auto">Klinisch</el-checkbox>
      <el-checkbox v-model="localTnm.tnm_extract.T.pathological" class="d-block p-0 m-0 h-auto">Pathologisch</el-checkbox>
      <el-checkbox v-model="localTnm.tnm_extract.T.ultrasound" class="d-block p-0 m-0 h-auto">Ultraschall</el-checkbox>
      <el-checkbox v-model="localTnm.tnm_extract.T.recurrent" class="d-block p-0 m-0 h-auto">Wiederkehrend</el-checkbox>
      <el-checkbox v-model="localTnm.tnm_extract.T.posttherapy" class="d-block p-0 m-0 h-auto">Präoperative Therapie</el-checkbox>

    </el-col>
    <el-col :span="8">
      <h3>-- N --</h3>

      <el-select v-model="localTnm.tnm_extract.N.value" filterable class="w-75 mb-3">
        <el-option
          v-for="item in (tnmSelectable.N ? tnmSelectable.N : [])"
          :key="item.id"
          :label="item.value_with_prefix"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-row>
        <el-col :span="12">
          <el-checkbox v-model="localTnm.tnm_extract.N.clinical" class="d-block p-0 m-0 h-auto">Klinisch</el-checkbox>
          <el-checkbox v-model="localTnm.tnm_extract.N.pathological" class="d-block p-0 m-0 h-auto">Pathologisch</el-checkbox>
          <el-checkbox v-model="localTnm.tnm_extract.N.ultrasound" class="d-block p-0 m-0 h-auto">Ultraschall</el-checkbox>
        </el-col>
        <el-col :span="12">
          <el-checkbox v-model="localTnm.tnm_extract.N.lymphnodes.sentinel" class="d-block p-0 m-0 h-auto">Sentinel</el-checkbox>
          <el-input size="mini" v-model="localTnm.tnm_extract.N.lymphnodes.affected" style="width:50px;"></el-input> / <el-input size="mini" v-model="localTnm.tnm_extract.N.lymphnodes.examined" style="width:50px;"></el-input>
        </el-col>
      </el-row>

    </el-col>
    <el-col :span="8">
      <h3>-- M --</h3>

      <el-select v-model="localTnm.tnm_extract.M.value" filterable class="w-75 mb-3">
        <el-option
          v-for="item in (tnmSelectable.M ? tnmSelectable.M : [])"
          :key="item.id"
          :label="item.value_with_prefix"
          :value="item.value"
        ></el-option>
      </el-select>

      <el-checkbox v-model="localTnm.tnm_extract.M.clinical" class="d-block p-0 m-0 h-auto">Klinisch</el-checkbox>
      <el-checkbox v-model="localTnm.tnm_extract.M.pathological" class="d-block p-0 m-0 h-auto">Pathologisch</el-checkbox>
    </el-col>
  </el-row>
  <el-row class="mt-4" v-if="localTnm.tnm_extract">
    <el-col :span="3">
      <h3>m</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.m" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>R</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.resection" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>V</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.vein" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>L</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.lymphatic" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>G</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.grade" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>Pn</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.perineural" class="w-75 mb-3"></el-input>
    </el-col>
    <el-col :span="3">
      <h3>UICC</h3>
      <el-input v-model="localTnm.tnm_extract.diverse.uicc" class="w-75 mb-3"></el-input>
    </el-col>
  </el-row>
  <small>Diag: <b>{{localDiagnosisId}}</b> <span v-if="tnmSelectedClassificationId"> Klassifikation <b>{{tnmSelectedClassificationId}}</b></span></small>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import patient from "../../pages/Patient.vue";
import _ from "lodash";

export default {
  name: "TNMExtract",
  props: ['modelValue', 'diagnosis_id', 'context', 'selectable_diagnosis', 'icdo3t', 'icdo3h'],
  emits: ['update:modelValue', 'cancerregistry-change'],
  data() {
    return {
      localTnm: {
        tnm: ''
      },
      localDiagnosisId: null,
      patientFinding: null,
      tnmSelectable: { T:[], N:[], M:[] },
      tnmSelectedClassificationId: null,
      tnmSelectedId:  null,
      tnmExtractTemplate: {
        T: {
          clinical: false, //c
          pathological: false, //p
          recurrent: false, //r
          posttherapy: false, //y
          ultrasound: false, //u
          value: '',
        },
        N: {
          clinical: false, //c
          pathological: false, //p
          posttherapy: false, //y
          ultrasound: false, //u
          lymphnodes: {
            affected: null,
            examined: null,
            sentinel: false
          },
          value: '',
        },
        M: {
          clinical: false, //c
          pathological: false, //p
          value: ''
        },
        diverse: {
          vein: '',
          lymphatic: '',
          grade: '',
          resection: '',
          perineural: '',
          uicc: '',
          m: ''
        },
        unknown: []
      }
    }
  },
  computed: {
    patient() {
      return patient
    },
    ...mapGetters('TNM', ['tnmOptions']),
    ...mapGetters('patients', ['currentPatient']),
  },
  mounted: async function () {
    this.init()
    this.loadTNMOptions()

  },
  watch: {
    'modelValue': {
      handler() {
        this.init()
      },
      deep: true
    },
    'diagnosis_id': function() {
      this.loadTNMOptions()
    },
    'localTnm': {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    },
    'localTnm.patient_finding_id': function(patient_finding_id) {
      if(this.context !== 'diagnosis') return
      this.patientFinding = null
      this.loadPatientFinding(patient_finding_id)
    },
    'localTnm.tnm_extract.T.value': function() {
      this.loadUICCStadium()
    },
    'localTnm.tnm_extract.N.value': function() {
      this.loadUICCStadium()
    },
    'localTnm.tnm_extract.M.value': function() {
      this.loadUICCStadium()
    },
    'tnmSelectedId': function(tnmSelectedId) {
      if(tnmSelectedId) {
        this.tnmSelectable =_.find(this.tnmOptions, (o) => o.id === tnmSelectedId)
        if(this.tnmSelectable) this.tnmSelectedClassificationId = this.tnmSelectable.classification_id
      }
    }
  },
  methods: {
    ...mapActions('TNM', ['getTNMOptions', 'getUICCStadium']),
    ...mapActions('patients', ['getFindingOfPatient']),
    async init() {
      this.localTnm = this.modelValue ? this.modelValue : { tnm: '' }
      if(!this.localTnm.tnm_extract) this.localTnm.tnm_extract = this.tnmExtractTemplate
    },
    async loadTNMOptions() {
      const selectedDiagnosis = _.find(this.selectable_diagnosis, (d) => d.id === this.localTnm.patient_diagnosis_id)
      this.localDiagnosisId= selectedDiagnosis ? selectedDiagnosis.diagnosis_id : this.diagnosis_id
      await this.getTNMOptions(this.localDiagnosisId)
      this.tnmSelectedId = null
      if(this.tnmOptions && this.tnmOptions.length === 1) {
        this.tnmSelectedId = this.tnmOptions[0].id
      }
    },
    async loadUICCStadium() {
      if(this.tnmSelectable.classification_id
        && this.localTnm.tnm_extract.T.value
        && this.localTnm.tnm_extract.N.value
        && this.localTnm.tnm_extract.M.value) {
          this.localTnm.tnm_extract.diverse.uicc = await this.getUICCStadium({
            classification_id: this.tnmSelectable.classification_id,
            T: this.localTnm.tnm_extract.T.value,
            N: this.localTnm.tnm_extract.N.value,
            M: this.localTnm.tnm_extract.M.value
          })
      }
    },
    onExtractTNM() {
      const regex = /([cpyrumRLVpPn]*)([0-9]+.TD|T[a-zA-Z\d]*|N[a-zA-Z\d]*|M[a-zA-Z\d]*|Tis|L[a-zA-Z\d]*|Pn[a-zA-Z0-9\d]*|R[a-zA-Z\d]*|G[a-zA-Z\d]*|m.[0-9]*|V[0-9a-zA-Z\d]*|[0-9]+\/[0-9]+|sn.*[0-9]+\/[0-9]+)/g;
      const matches = this.localTnm.tnm.match(regex);
      // let tnm = {}

      this.localTnm.tnm_extract = {
        T: {
          clinical: false, //c
          pathological: false, //p
          recurrent: false, //r
          posttherapy: false, //y
          ultrasound: false, //u
          value: '',
        },
        N: {
          clinical: false, //c
          pathological: false, //p
          posttherapy: false, //y
          ultrasound: false, //u
          lymphnodes: {
            affected: null,
            examined: null,
            sentinel: false
          },
          value: '',
        },
        M: {
          clinical: false, //c
          pathological: false, //p
          value: ''
        },
        diverse: {
          vein: '',
          lymphatic: '',
          grade: '',
          resection: '',
          perineural: '',
          uicc: '',
          m: ''
        },
        unknown: []
      }

      // let categories = []

      if (matches) {

        matches.forEach(match => {
          let categoryPrefix = match.charAt(0);
          let categoryType = match.charAt(1);

          if(categoryPrefix === "P" || categoryType.match(/[a-z]/)) {
            // second char still a prefix or it is Pn
            categoryPrefix = match.charAt(0) + match.charAt(1)
            categoryType = match.charAt(2);
          }

          if(categoryType.match(/[a-z]/)) {
            // second char still a prefix
            categoryPrefix = match.charAt(0) + match.charAt(1) + match.charAt(2)
            categoryType = match.charAt(3);
          }

          let categoryValue = match.slice(categoryPrefix.length).toUpperCase();

          /*if (categoryType == 'T') {
            tnm[categoryType] = categoryValue.match(/(TIS)|([0-9])+/g) ? categoryValue.match(/(TIS)|([0-9])+/g)[0] : ''
          }
          if (categoryType == 'N') {
            tnm[categoryType] = categoryValue.match(/(1MI)|([0-9])+/g) ? categoryValue.match(/(1MI)|([0-9])+/g)[0] : ''
          }
          if (categoryType == "M") {
            tnm[categoryType] = categoryValue.match(/[0-9]+/g) ? categoryValue.match(/[0-9]+/g)[0] : ''
          }*/

          let unknown = true

          if (categoryType == 'T') {
            this.localTnm.tnm_extract.T.pathological = (categoryPrefix.indexOf('p') !== -1)
            this.localTnm.tnm_extract.T.clinical = (categoryPrefix.indexOf('c') !== -1)
            this.localTnm.tnm_extract.T.recurrent = (categoryPrefix.indexOf('r') !== -1)
            this.localTnm.tnm_extract.T.posttherapy = (categoryPrefix.indexOf('y') !== -1)
            this.localTnm.tnm_extract.T.ultrasound = (categoryPrefix.indexOf('u') !== -1)
            this.localTnm.tnm_extract.T.value = categoryValue.replace("T", "")
            unknown = false
          }
          else if (categoryType == 'N') {
            this.localTnm.tnm_extract.N.pathological = (categoryPrefix.indexOf('p') !== -1)
            this.localTnm.tnm_extract.N.clinical = (categoryPrefix.indexOf('c') !== -1)
            this.localTnm.tnm_extract.N.posttherapy = (categoryPrefix.indexOf('y') !== -1)
            this.localTnm.tnm_extract.N.ultrasound = (categoryPrefix.indexOf('u') !== -1)
            this.localTnm.tnm_extract.N.value = categoryValue.replace("N", "")
            unknown = false

          }
          else if (categoryType == 'M') {
            this.localTnm.tnm_extract.M.pathological = (categoryPrefix.indexOf('p') !== -1)
            this.localTnm.tnm_extract.M.clinical = (categoryPrefix.indexOf('c') !== -1)
            this.localTnm.tnm_extract.M.value = categoryValue.replace("M", "")
            unknown = false
          }

          if (categoryPrefix == 'V') {
            this.localTnm.tnm_extract.diverse.vein = categoryValue
            unknown = false
          }
          else if (categoryPrefix == 'R') {
            console.log(categoryType, categoryPrefix, categoryValue)
            this.localTnm.tnm_extract.diverse.resection = categoryValue
            unknown = false
          }
          else if (categoryPrefix == 'L') {
            this.localTnm.tnm_extract.diverse.lymphatic = categoryValue
            unknown = false
          }
          else if (categoryPrefix == 'G') {
            this.localTnm.tnm_extract.diverse.grade = categoryValue
            unknown = false
          }
          else if (categoryPrefix == 'Pn') {
            this.localTnm.tnm_extract.diverse.perineural = categoryValue
            unknown = false
          }
          else if (categoryPrefix == 'm') {
            if(categoryValue == ' ') categoryValue = "m"
            this.localTnm.tnm_extract.diverse.m = categoryValue
            unknown = false
          }

          if(match.indexOf("TD") !== -1) {
            this.localTnm.tnm_extract.T.value = this.localTnm.tnm_extract.T.value + " " + match
            unknown = false
          }

          if (match.indexOf('/') !== -1) {

            unknown = false
            let nodes
            if (match.indexOf('sn') !== -1) {
              this.localTnm.tnm_extract.N.lymphnodes.sentinel = true
              nodes = match.match(/[0-9]+\/[0-9]+/g)[0]
            } else {
              nodes = match;
            }
            let exnodes = nodes.split('/')
            this.localTnm.tnm_extract.N.lymphnodes.affected = exnodes[0]
            this.localTnm.tnm_extract.N.lymphnodes.examined = exnodes[1]
          }

          if(unknown) {
            this.localTnm.tnm_extract.unknown.push(match)
          }
        })
      }

    },
    onCancerregistryChange() {
      this.$emit('cancerregistry-change', this.localTnm)
    },
    async loadPatientFinding(patient_finding_id) {
      if(patient_finding_id) {
        this.patientFinding = await this.getFindingOfPatient({ patient_id: this.currentPatient.id, finding_id: patient_finding_id })
      }
    }
  }
}
</script>


<style scoped>

</style>
