import axios from 'axios'

// initial state
const state = () => ({
    protocolown: [],
})

// getters
const getters = {
  protocolown: ({ protocolown }) => protocolown,
}

// actions
const actions = {
    async getProtocolOwn({ commit }) {
        const protocolown = (await axios.get('/protocolown')).data
        commit('setProtocolOwn', protocolown)
    },
    async saveProtocolOwn(state, protocolOwnData) {

        try {
            const result = await axios.post('/protocolown', protocolOwnData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteProtocolOwn(state, protocolOwnData) {

        try {
            const result = await axios.delete('/protocolown/'+protocolOwnData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setProtocolOwn(state, protocolown) {
        state.protocolown = protocolown
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
