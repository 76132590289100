import axios from 'axios'

// initial state
const state = () => ({
    diagnosticmethods: [],
})

// getters
const getters = {
    diagnosticmethods: ({ diagnosticmethods }) => diagnosticmethods,
}

// actions
const actions = {
    async getDiagnosticMethods({ commit }) {
        const diagnosticmethods = (await axios.get('/meta/diagnosticmethods/')).data
        commit('setDiagnosticMethods', diagnosticmethods)
    }
}

// mutations
const mutations = {
    setDiagnosticMethods(state, diagnosticmethods) {
        state.diagnosticmethods = diagnosticmethods
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
