import axios from 'axios'

// initial state
const state = () => ({
    therapyconcepts: []
})

// getters
const getters = {
  therapyconcepts: ({ therapyconcepts }) => therapyconcepts,
}

// actions
const actions = {
    async getTherapyConcepts({ commit }, payload) {

        let param = '?'
        if(payload && payload.diagnosis) param += '&diagnosis='+payload.diagnosis
        if(payload && payload.treatmentsituation) param += '&treatmentsituation='+payload.treatmentsituation
        if(payload && payload.biomarker) param += '&biomarker='+payload.biomarker
        if(payload && payload.protocol) param += '&protocol='+payload.protocol
        if(payload && payload.only_protocol_own) param += '&only_protocol_own=1'

        const therapyconcepts = (await axios.get('/therapyconcepts/'+param)).data
        commit('setTherapyConcepts', therapyconcepts)

    }
}

// mutations
const mutations = {
    setTherapyConcepts(state, therapyconcepts) {
      state.therapyconcepts = therapyconcepts
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
