<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :span="10" style="line-height:50px">
            <span v-if="currentPatient"><b>{{fullInfo}}</b></span>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="10">
            <el-select size="medium" class="w-100 mt-2" v-if="currentPatient && showDiagnosisSwitch" v-model="currentSelectedDiagnosis" filterable>
              <el-option
                  v-for="item in currentPatient.diagnosis"
                  :key="item.id"
                  :label="($filters.germanDate(item.date) + ', ' + item.meta.diagnosis.title)"
                  :value="item.id">
              </el-option>
            </el-select>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="2" style="line-height:50px; text-align:right">
            <el-button @click="navigateToStart" class="mr-3 pl-3 pr-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :span="2" class="text-right align-content-end">
            <main-menu></main-menu>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row>
          <el-col :span="24">

            <el-card class="box-card">

              <router-view></router-view>

            </el-card>

          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "Patient",
  props: [],
  components: {MainMenu},
  data() {
    return {
      showDiagnosisSwitch: false,
      currentSelectedDiagnosis: null
    }
  },
  mounted: async function () {
    await this.$store.dispatch('patients/setCurrentPatient', this.$route.params.id)
    document.title = this.fullInfo

    if(this.$route.path.indexOf("diagnosis") !== -1) {
      this.showDiagnosisSwitch = true
      this.currentSelectedDiagnosis = this.$route.params.patient_diagnosis_id ? parseInt(this.$route.params.patient_diagnosis_id) : null
    }

  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
  },
  watch: {
    currentSelectedDiagnosis: function (val) {
      this.$router.push({ name: 'edit_diagnosis', params: { patient_diagnosis_id: val }})
    }
  },
  methods: {
    navigateToStart() {
      this.$router.push({ name: 'dashboard' } )
    }
  }
}
</script>
<style scoped>
</style>
