import axios from 'axios'

// initial state
const state = () => ({
    therapyunits: [],
})

// getters
const getters = {
    therapyunits: ({ therapyunits }) => therapyunits,
}

// actions
const actions = {
    async getTherapyUnits({ commit }) {
        const therapyunits = (await axios.get('/meta/therapyunits/')).data
        commit('setTherapyUnits', therapyunits)
    },
    async saveTherapyUnit(state, therapyUnitData) {

        try {
            const result = await axios.post('/meta/therapyunits', therapyUnitData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteTherapyUnit(state, therapyUnitData) {

        try {
            const result = await axios.delete('/meta/therapyunits/'+therapyUnitData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setTherapyUnits(state, therapyunits) {
        state.therapyunits = therapyunits
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
