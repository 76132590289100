<template>
  <div>
    <survey-patient-task-form-modal :surveytask="currentTask" v-model="showSurveyTaskModal" @after-save="loadPatientSurveyTasks"></survey-patient-task-form-modal>

    <div v-if="currentSurveyPatient">

        <el-row class="mt-3">
          <el-col :span="18">
            <h4 v-if="currentSurveyPatient.id == 0">Neuen Patient zuweisen</h4>
            <h4 v-else>{{currentSurveyPatient.patient.name}}, {{currentSurveyPatient.patient.firstname}}, {{$filters.germanDate(currentSurveyPatient.patient.birthday)}}</h4>
          </el-col>
          <el-col :span="4" class="text-right">
            <el-button icon="el-icon-top-right" @click="onBtnDacyDashboardClick">DACY Dashboard</el-button>
          </el-col>
        </el-row>
          <el-tabs>
            <el-tab-pane label="Einverständniserklärung">

              <el-row class="mt-2" v-if="currentSurveyPatient.id === null">
                <el-col :span="7">
                  <h6>Studie</h6>
                  <el-select size="medium" class="w-100" v-model="currentSurveyPatient.survey_local_id" filterable>
                    <el-option :value="null">-- Keine Studie ausgewählt --</el-option>
                    <el-option
                      v-for="item in surveysForSelection"
                      :key="item.id"
                      :label="item.title_short"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>

              </el-row>

              <el-row class="mt-4">

                <el-col :span="7">
                  <h6>Datum Einverständniserklärung</h6>
                  <el-date-picker
                    class="w-100"
                    v-model="currentSurveyPatient.date_agreement"
                    type="date"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    placeholder="Datum auswählen">
                  </el-date-picker>
                </el-col>
                <el-col :span="7" :offset="1">
                  <h6>Datum Screen</h6>
                  <el-date-picker
                    class="w-100"
                    v-model="currentSurveyPatient.date_screen"
                    type="date"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    placeholder="Datum auswählen">
                  </el-date-picker>
                </el-col>
                <el-col :span="7" :offset="1">
                  <h6>Datum EOS</h6>
                  <el-date-picker
                    class="w-100"
                    v-model="currentSurveyPatient.date_eos"
                    type="date"
                    format="DD.MM.YYYY"
                    value-format="YYYY-MM-DD"
                    placeholder="Datum auswählen">
                  </el-date-picker>
                </el-col>

              </el-row>
              <el-row class="mt-4">
                <el-col :span="7">
                  <h6>Patienten ID</h6>
                  <el-input v-model="currentSurveyPatient.patient_survey_id"></el-input>
                </el-col>

                <el-col :span="7"  :offset="1">
                  <h6>Status</h6>
                  <el-select size="medium" class="w-100" v-model="currentSurveyPatient.status_id" filterable>
                    <el-option :value="null">-- Keinen Status ausgewählt --</el-option>
                    <el-option
                      v-for="item in patientSurveyStates"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>

              <el-row class="mt-4">
                <el-col :span="12">
                  <el-popconfirm
                    v-if="currentSurveyPatient.id !== null"
                    :title="'Sie möchten die Teilnahme des Probanden löschen?'"
                    confirmButtonText='Ja, löschen'
                    confirmButtonType="danger"
                    cancelButtonText='Nein'
                    iconColor="red"
                    @confirm="onDeleteSurveyPatient">
                    <template #reference>
                      <el-button type="danger" icon="el-icon-delete">Löschen</el-button>
                    </template>
                  </el-popconfirm>
                </el-col>
                <el-col :span="12" class="text-right">
                  <el-button type="warning" :disabled="isSaving" @click="onSaveSurveyPatient">Einverständniserklärung speichern</el-button>
                </el-col>
              </el-row>

            </el-tab-pane>
            <el-tab-pane label="Aufgaben" :disabled="(currentSurveyPatient.id === null)">

              <div class="text-right">
                <el-button type="warning" @click="openSurveyTaskModal">Neue Aufgabe</el-button>
              </div>

              <el-table
                :data="patientSurveyTasks"
                :default-sort = "{prop: 'date', order: 'asc'}"
                style="width: 100%"
                @row-click="onPatientSurveyTaskRowClick"
                stripe>
                <el-table-column
                  label="Aufgabe">
                  <template #default="scope">
                    {{ scope.row.surveytask.title }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="date"
                  label="Datum"
                  sortable>
                  <template #default="scope">
                    {{ $filters.germanDate(scope.row.date) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="is_done"
                  label="Erledigt"
                  sortable>
                  <template #default="scope">
                    <span v-if="scope.row.is_done == 1">Ja</span>
                    <span v-if="scope.row.is_done == 0">Nein</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="duration"
                  label="Dauer"
                  sortable>
                  <template #default="scope">
                    <span v-if="scope.row.duration"> {{scope.row.duration}} Min</span>
                  </template>
                </el-table-column>
              </el-table>

            </el-tab-pane>
          </el-tabs>

    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SurveyPatientTaskFormModal from "./SurveyPatientTaskFormModal";
import _ from "lodash";

export default {
  name: "SurveyPatientForm",
  props: ['surveyPatient', 'survey', 'selectionSurveys'],
  emits: [
    'after-save'
  ],
  components: {SurveyPatientTaskFormModal},
  data() {
    return {
      currentSurveyPatient: null,
      showSurveyTaskModal: false,
      currentTask: null,
      isSaving: false
    }
  },
  mounted: async function () {
    await this.loadSurveyPatient()
  },
  computed: {
    ...mapGetters('surveys', ['surveyTasks', 'patientSurveyTasks', 'surveys', 'patientSurveyStates']),
    currentSurveyTask() {
      if(this.currentTask.surveytask) {
        return _.find(this.surveyTasks, (o) => o.id ==this.currentTask.surveytask)
      }
      return null
    },
    surveysForSelection() {
      if(this.selectionSurveys) return this.selectionSurveys
      else return this.surveys
    }
  },
  watch: {
    'surveyPatient': async function() {
      await this.loadSurveyPatient()
      await this.getSurveys()
    }
  },
  methods: {
    ...mapActions('surveys', ['getSurveyTasks', 'savePatientSurveyTask', 'getPatientSurveyTasks', 'getSurveys', 'savePatientSurvey', 'deletePatientSurvey']),
    async loadSurveyPatient() {

      if(this.surveyPatient === null) return

      if(!this.surveyPatient.patient_id && this.surveyPatient.id) {
        // patient
        this.currentSurveyPatient = {
          id: null,
          patient_id : this.surveyPatient.id,
          survey_local_id : this.survey ? this.survey.id : null,
          patient : this.surveyPatient,
          status_id: 1
        }
      }
      else {
        // survey patient
        this.currentSurveyPatient = _.cloneDeep(this.surveyPatient)
      }

      this.loadPatientSurveyTasks()
    },
    async loadPatientSurveyTasks() {
      this.getPatientSurveyTasks( { patient_id: this.currentSurveyPatient.patient_id, survey_local_id: this.currentSurveyPatient.survey_local_id })
    },
    async onSaveSurveyPatient() {

      this.isSaving = true

      if(this.currentSurveyPatient.survey_local_id === null) {
        this.$notify.error({
          message: "Bitte wählen Sie eine Studie aus. Speichervorgang wurde abgebrochen.",
          position: 'bottom-left'
        });
      }
      else if(this.currentSurveyPatient.date_agreement === null || this.currentSurveyPatient.date_agreement === undefined) {
        this.$notify.error({
          message: "Bitte wählen Sie ein Datum der Einverständniserklärung aus. Speichervorgang wurde abgebrochen.",
          position: 'bottom-left'
        });
      }
      else {
        const result = await this.savePatientSurvey(this.currentSurveyPatient)
        if(!result) {
          this.$notify.error({
            message: "Fehler beim Speichervorgang",
            position: 'bottom-left'
          });
        }
        else {
          this.currentSurveyPatient = _.cloneDeep(result)
          this.$emit("after-save")
        }
      }

      this.isSaving = false

    },
    async onDeleteSurveyPatient() {
      const result = await this.deletePatientSurvey(this.currentSurveyPatient)
      if(!result) {
        this.$notify.error({
          message: "Fehler beim Löschvorgang",
          position: 'bottom-left'
        });
      }
      else {
        this.currentSurveyPatient = null
        this.$emit("after-delete")
      }
    },
    async openSurveyTaskModal() {
      await this.getSurveyTasks({ survey: this.currentSurveyPatient.survey_local_id })
      this.currentTask = {
        id:0,
        surveytask: null,
        patient_id: this.currentSurveyPatient.patient_id,
        survey_local_id: this.currentSurveyPatient.survey_local_id
      }
      this.showSurveyTaskModal = true
    },
    /* async onSavePatientSurveyTask() {
      await this.savePatientSurveyTask(this.currentTask)
      this.showSurveyTaskModal = false
      this.getPatientSurveyTasks( { patient_id: this.currentSurveyPatient.patient_id, survey_local_id: this.currentSurveyPatient.survey_local_id })
    }, */
    async onPatientSurveyTaskRowClick(row) {
      await this.getSurveyTasks({ survey: this.currentSurveyPatient.survey_local_id })
      this.currentTask = _.cloneDeep(row)
      this.showSurveyTaskModal = true
      console.log(this.currentTask, this.showSurveyTaskModal)
    },
    onBtnDacyDashboardClick() {
      window.open('/app/dashboard?patient='+this.currentSurveyPatient.patient_id, '_blank', 'top=' + (window.screenY + 50) +',left=' + (window.screenX + 50))
    }
  }
}
</script>
<style scoped>
</style>
