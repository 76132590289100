import axios from 'axios'

// initial state
const state = () => ({
    cancerregistryentries: []
})

// getters
const getters = {
  cancerregistryentries: ({ cancerregistryentries }) => cancerregistryentries,
}

// actions
const actions = {
    async getCancerregistryEntries({ commit }, sent) {
      const entries = (await axios.get('/cancerregistry?sent=' + (sent ? 1 : 0 ))).data
      commit('setCancerregistryEntries', entries)
    },
    async generateAllXml(state, patient_id) {
      try {
        const result = await axios.post('/cancerregistry/patient/' + patient_id)
        return result.data
      }
      catch (e) {
        return false
      }
    },
    async generateDiagnosisXml(state, diagnosis) {
      try {
        await axios.post('/cancerregistry/', { type: 'diagnosis', diagnosis: diagnosis })
      }
      catch (e) {
        return false
      }
      return true
    },
    async generateTumorconferenceXml(state, diagnosis) {
      try {
        await axios.post('/cancerregistry/', { type: 'tumorconference', diagnosis: diagnosis })
      }
      catch (e) {
        return false
      }
      return true
    },
    async generateTherapyOPXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'therapy_op', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateSystemTherapyXml(state, therapy) {
        try {
            await axios.post('/cancerregistry/', { type: 'systemtherapy', therapy: therapy })
        }
        catch (e) {
            return false
        }

        return true
    },
    async generateRadioTherapyXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'radio', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateTherapyXml(state, therapy) {
      try {
        await axios.post('/cancerregistry/', { type: 'therapy', therapy: therapy })
      }
      catch (e) {
        return false
      }

      return true
    },
    async generateDeathXml(state, patient) {
      try {
        await axios.post('/cancerregistry/', { type: 'death', patient: patient })
      }
      catch (e) {
        return false
      }
      return true
    },
}

// mutations
const mutations = {
  setCancerregistryEntries(state, cancerregistryentries) {
    state.cancerregistryentries = cancerregistryentries
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
