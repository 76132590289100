import axios from 'axios'

// initial state
const state = () => ({
    diagnosis_modules: [],
})

// getters
const getters = {
  diagnosis_modules: ({ diagnosis_modules }) => diagnosis_modules,
}

// actions
const actions = {
    async getDiagnosisModules({ commit }) {
        const diagnosis_modules = (await axios.get('/meta/diagnosis_modules/')).data
        commit('setDiagnosisModules', diagnosis_modules)
    },
}

// mutations
const mutations = {
  setDiagnosisModules(state, diagnosis_modules) {
        state.diagnosis_modules = diagnosis_modules
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
