import axios from 'axios'

// initial state
const state = () => ({
    doctors: [],
})

// getters
const getters = {
    doctors: ({ doctors }) => doctors,
}

// actions
const actions = {
    async getDoctors({ commit }) {
        const doctors = (await axios.get('/meta/doctors/')).data
        commit('setDoctors', doctors)
    },
    async saveDoctor(state, doctorData) {

        try {
            const result = await axios.post('/meta/doctors', doctorData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteDoctor(state, doctorData) {

        try {
            const result = await axios.delete('/meta/doctors/'+doctorData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setDoctors(state, doctors) {
        state.doctors = doctors
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
