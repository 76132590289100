<template>
  <div>
    <el-dialog
      :title="'Aufgabe ' + (currentSurveyTask && currentSurveyTask.id ? ' bearbeiten' : ' hinzufügen')"
      width="60%"
      v-model="showForm"
      :before-close="closeDialog"
    >

      <survey-patient-task-form :surveytask="currentSurveyTask" @after-save="onAfterSaveTask"></survey-patient-task-form>
    </el-dialog>

  </div>
</template>

<script>
import SurveyPatientTaskForm from "./SurveyPatientTaskForm";

export default {
  name: "SurveyPatientTaskFormModal",
  props: ['modelValue', 'surveytask'],
  components: {SurveyPatientTaskForm},
  data() {
    return {
      currentSurveyTask: null,
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'surveytask': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentSurveyTask = this.surveytask
      console.log(this.showForm, this.currentSurveyTask)
    },
    closeDialog() {
      this.showForm = false
      this.currentSurveyTask = null
      this.$emit('update:modelValue', false)
    },
    onAfterSaveTask() {
      this.$emit("after-save")
      this.closeDialog()
    }
  }
}
</script>
<style scoped>
</style>
