<template>
  <div>
    <el-dialog v-if="currentSurveyPatient"
               width="75%"
               v-model="showForm"
               :before-close="closeDialog">
      <survey-patient-form :survey="currentSurvey" :selection-surveys="currentSelectionSurveys" @after-save="onAfterSaveSurveyPatientForm" :survey-patient="currentSurveyPatient" @after-delete="closeDialog"></survey-patient-form>
    </el-dialog>

  </div>
</template>

<script>
import SurveyPatientForm from "./SurveyPatientForm";

export default {
  name: "SurveyPatientFormModal",
  props: ['modelValue', 'surveyPatient', 'survey', 'selectionSurveys'],
  emits: [
    'after-save'
  ],
  components: {SurveyPatientForm},
  data() {
    return {
      currentSurveyPatient: null,
      currentSurvey: null,
      currentSelectionSurveys: null,
      showForm: false
    }
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'surveyPatient': function() {
      this.init()
    },
    'survey': function() {
      this.init()
    },
    'selectionSurveys': function() {
      this.init()
    },
    'modelValue': function() {
      this.init()
    }
  },
  methods: {
    init() {
      this.showForm = this.modelValue
      this.currentSurveyPatient = this.surveyPatient
      this.currentSurvey = this.survey
      if(this.selectionSurveys) this.currentSelectionSurveys = this.selectionSurveys
    },
    closeDialog() {
      this.showForm = false
      this.currentSurveyPatient = null
      this.currentSurvey = null
      this.currentSelectionSurveys = null
      this.$emit('update:modelValue', false)
    },
    onAfterSaveSurveyPatientForm() {
      this.$emit('after-save')
      this.closeDialog()
    }
  }
}
</script>
<style scoped>
</style>
