import axios from 'axios'

// initial state
const state = () => ({
    practitioner: [],
})

// getters
const getters = {
    practitioner: ({ practitioner }) => practitioner,
}

// actions
const actions = {
    async getPractitioner({ commit }) {
        const practitioner = (await axios.get('/meta/practitioner/')).data
        commit('setPractitioner', practitioner)
    },
    async savePractitioner(state, practitionerData) {

        try {
            const result = await axios.post('/meta/practitioner', practitionerData)
            return result.data
        }
        catch (e) {
            return false
        }

    },
    async deleteDoctor(state, practitionerData) {

        try {
            const result = await axios.delete('/meta/practitioner/'+practitionerData.id)
            return result.data
        }
        catch (e) {
            return false
        }

    },
}

// mutations
const mutations = {
    setPractitioner(state, practitioner) {
        state.practitioner = practitioner
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
