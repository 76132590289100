<template>
  <div v-if="currentPatient">
    <patient-therapy-form v-if="currentTherapy" v-model="showTherapyForm" :therapy="currentTherapy" ></patient-therapy-form>
    <patient-finding-form-modal :finding="currentFinding" v-model="showFindingForm"></patient-finding-form-modal>

    <el-table
        ref="therapiesTable"
        :data="currentPatient.therapies"
        :default-sort = "{prop: 'date_start', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowClick"
        @row-contextmenu="onRowRightClick"
        @expand-change="onRowExpand"
        stripe>
      <el-table-column type="expand">
        <template #default="scope">
          <div v-if="!progressOfTherapies[scope.row.id]">Keine Verlaufsbeobachtungen vorhanden</div>
              <table v-else class="customtable w-100">
                <tr>
                  <th width="100">Datum</th>
                  <th width="150">Recist / Beurteilung</th>
                  <th>Befunde</th>
                  <th>Prozedere</th>
                  <th>Dosierung / Kommentar</th>
                </tr>
                <tbody :key="row.progress.evaluation.id" v-for="row in progressOfTherapies[scope.row.id]">
                  <tr @click="onProgressClick(row.progress.evaluation.id)" :class="{ clickable_row: row.progress.evaluation.findings && row.progress.evaluation.findings.length > 0 }">
                    <td>{{ $filters.germanDate(row.progress.evaluation.date) }}</td>
                    <td><span v-if="row.progress.evaluation.recist" :title="row.progress.evaluation.recist.title">{{row.progress.evaluation.recist.code}}</span></td>
                    <td>{{row.progress.evaluation.result}}</td>
                    <td>{{row.progress.evaluation.therapy_plan}}</td>
                    <td>{{row.progress.evaluation.comment}}</td>
                  </tr>
                  <tr v-if="row.progress.evaluation.findings && row.progress.evaluation.findings.length > 0" v-show="showFindingsForEvaluation[row.progress.evaluation.id]">
                    <td colspan="4">
                      <div :key="finding.id" v-for="finding in row.progress.evaluation.findings" class="customtable_wrapper expand-box ml-0">

                        <table class="customtable" style="width:auto;">
                          <tr>
                            <th width="60">
                              <el-button size="mini" type="warning" @click="onFindingClick(finding)" icon="el-icon-edit"></el-button>
                            </th>
                            <th width="150">Befund-Datum</th>
                            <th width="230">Diagnostisches Verfahren</th>
                            <th>Bewertung</th>
                          </tr>
                          <tr>
                            <td></td>
                            <td>{{ $filters.germanDate(finding.date) }}</td>
                            <td><span v-if="finding.meta.diagnosticmethod">{{finding.meta.diagnosticmethod.title}}</span></td>
                            <td>{{finding.evaluation}}</td>
                          </tr>
                        </table>
                        <table class="customtable" style="width:auto;">
                          <tr>
                            <th width="60"></th>
                            <th width="150">Diagnostiker</th>
                            <th width="230">klinische Einheit</th>
                            <th width="150">Kennung</th>
                            <th width="100">ICD10</th>
                            <th width="100">ICD-O-3</th>
                          </tr>
                          <tr>
                            <th width="60"></th>
                            <td><span v-if="finding.meta.doctor">{{finding.meta.doctor.title}}</span></td>
                            <td><span v-if="finding.meta.therapyunit">{{finding.meta.therapyunit.code}}</span></td>
                            <td>{{finding.code}}</td>
                            <td width="100">{{finding.icd10}}</td>
                            <td width="100">{{finding.icdo3}}</td>
                          </tr>
                        </table>
                        <table v-if="finding.comment" class="customtable">
                          <tr>
                            <th width="60"></th>
                            <th>Kommentar</th>
                          </tr>
                          <tr>
                            <td></td>
                            <td>{{finding.comment}}</td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
        </template>
      </el-table-column>
      <el-table-column
          prop="date_start"
          label="Beginn"
          width="100"
          sortable>
        <template #default="scope">
          <span style="white-space: nowrap">{{ $filters.germanDate(scope.row.date_start, false, scope.row.date_start_precision) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="date_end"
          label="Ende"
          width="100"
          sortable>
        <template #default="scope">
          <span v-if="scope.row.date_end" style="white-space: nowrap">{{ $filters.germanDate(scope.row.date_end, false, scope.row.date_end_precision) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="meta.diagnosis.title"
          label="Diagnose, Behandlungssituation"
          width="100"
          >
        <template #default="scope">
          <div v-if="scope.row.meta.diagnosis">
            <span :title="scope.row.meta.diagnosis.title">{{scope.row.meta.diagnosis.code}}</span>,
            <span v-if="scope.row.meta.treatmentsituation" :title="scope.row.meta.treatmentsituation.title">{{scope.row.meta.treatmentsituation.code}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="meta.therapy.title"
          label="Therapieprinzip">
        <template #default="scope">
          <el-tooltip
              content="laufende Therapie"
              placement="bottom"
              v-if="scope.row.completed === null"
          >
            <el-tag type="warning" size="mini" class="mr-1">L</el-tag>
          </el-tooltip>
          <el-tooltip
              content="geplante Therapie"
              placement="bottom"
              v-if="scope.row.completed === 2"
          >
            <el-tag size="mini" type="warning" class="mr-1">P</el-tag>
          </el-tooltip>
          <span v-if="scope.row.meta.therapy && scope.row.meta.therapy.title">{{ scope.row.meta.therapy.title }}</span>
          <span v-if="scope.row.meta.therapy && !scope.row.meta.therapy.title">{{ scope.row.meta.therapy.code }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="comment"
          label="Beschreibung / Kommentar">
      </el-table-column>
      <el-table-column
          prop="meta.protocol.title"
          label="Protokoll / Therapie">
        <template #default="scope">
          <span v-if="scope.row.meta.protocol">
            {{scope.row.meta.protocol.title}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          width="150">
        <template #default="scope">
          <div class="text-right">
            <el-button size="mini" type="warning" @click="onRowClick(scope.row)" icon="el-icon-edit"></el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PatientTherapyForm from "@/components/patient/PatientTherapyForm";
import PatientFindingFormModal from "./patient/PatientFindingFormModal";

export default {
  name: "PatientTherapies",
  props: [],
  components: {
    PatientTherapyForm,
    PatientFindingFormModal
  },
  data () {
    return {
      showTherapyForm: false,
      currentTherapy: null,
      showFindingsForEvaluation: [],
      showFindingForm: false,
      currentFinding: null
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapState('patients', ['currentPatient']),
    progressOfTherapies() {

      let progressOfTherapies =  []
      if(this.currentPatient && this.currentPatient.progress && this.currentPatient.progress.length) {
        for(const progress of this.currentPatient.progress) {

          if(!progressOfTherapies[progress.patient_therapy_id]) progressOfTherapies[progress.patient_therapy_id] = []

          for(const evaluation of progress.evaluations) {
            progressOfTherapies[progress.patient_therapy_id].push({
              progress: {
                date_start: progress.date_start,
                date_end: progress.date_end,
                evaluation: {
                  id: evaluation.id,
                  date: evaluation.date,
                  recist: evaluation.meta.recist,
                  result: evaluation.result,
                  comment: evaluation.comment,
                  therapy_plan: evaluation.therapy_plan,
                  findings: evaluation.findings
                }
              }
            })
          }

        }
      }
      return progressOfTherapies

    }
  },
  methods: {
    onRowClick(row) {
      this.currentTherapy = row
      this.showTherapyForm = true
    },
    onRowRightClick(row) {
      this.$refs.therapiesTable.setCurrentRow(row)
      this.$refs.therapiesTable.toggleRowExpansion(row)
    },
    onRowExpand(row) {
      this.$refs.therapiesTable.setCurrentRow(row)
    },
    onProgressClick(id) {
      if(this.showFindingsForEvaluation[id]) this.showFindingsForEvaluation[id] = false
      else this.showFindingsForEvaluation[id] = true
    },
    onFindingClick(finding) {
      this.currentFinding = finding
      this.showFindingForm = true
    }
}
}
</script>
<style scoped>
</style>
