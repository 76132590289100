import axios from 'axios'

// initial state
const state = () => ({
    devices: [],
})

// getters
const getters = {
    devices: ({ devices }) => devices,
}

// actions
const actions = {
    async getDevices({ commit }) {
        const devices = (await axios.get('/devices/')).data
        commit('setDevices', devices)
    }
}

// mutations
const mutations = {
    setDevices(state, devices) {
        state.devices = devices
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
