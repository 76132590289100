class DacyServer {

    static instance;

    constructor() {
    }

    static getInstance() {
        if (DacyServer.instance) {
            return DacyServer.instance;
        }
        DacyServer.instance = new DacyServer();
        return DacyServer.instance;
    }

    setService(service) {
        DacyServer.instance.service = service
        console.log("found dacy server at " + (DacyServer.instance.service.protocol ? DacyServer.instance.service.protocol : 'http') + "://" + (DacyServer.instance.service.host || DacyServer.instance.service.ip) + ':' + DacyServer.instance.service.port)
    }

    getURL() {
        console.log("get dacy server url")
        return (DacyServer.instance.service.protocol ? DacyServer.instance.service.protocol : 'http') + '://' + (DacyServer.instance.service.host || DacyServer.instance.service.ip) + ':' + DacyServer.instance.service.port
    }
}

export default DacyServer
