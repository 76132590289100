import axios from 'axios'

// initial state
const state = () => ({
    treatmentsituations: [],
})

// getters
const getters = {
    treatmentsituations: ({ treatmentsituations }) => treatmentsituations,
}

// actions
const actions = {
    async getTreatmentsituations({ commit }) {
        const treatmentsituations = (await axios.get('/meta/treatmentsituations/')).data
        commit('setTreatmentsituations', treatmentsituations)
    }
}

// mutations
const mutations = {
    setTreatmentsituations(state, treatmentsituations) {
        state.treatmentsituations = treatmentsituations
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
