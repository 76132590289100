import axios from 'axios'

// initial state
const state = () => ({
    sideeffecttypes: []
})

// getters
const getters = {
    sideeffecttypes: ({ sideeffecttypes }) => sideeffecttypes,
}

// actions
const actions = {
    async getSideEffectTypes({ commit }) {
        const sideeffecttypes = (await axios.get('/meta/sideeffecttypes')).data
        commit('setSideEffectTypes', sideeffecttypes)
    }
}

// mutations
const mutations = {
  setSideEffectTypes(state, sideeffecttypes) {
        state.sideeffecttypes = sideeffecttypes
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
